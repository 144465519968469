import ApiClient, { IApiClient } from './ApiClient';
import { BackendlessSkuRecord } from '../types';

const urlSubPath = '/data/SKUs';

export default class SKUApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }

  public fetchSKUsCount = async (params: URLSearchParams): Promise<number> => {
    const data = await this.apiClient.get<number>(`${urlSubPath}/count`, { params });
    return data;
  };

  public fetchSKUs = async (params: URLSearchParams): Promise<BackendlessSkuRecord[]> => {
    const data = await this.apiClient.get<BackendlessSkuRecord[]>(`${urlSubPath}`, { params });
    return data;
  };

  public updateSKU = async (kit: BackendlessSkuRecord): Promise<BackendlessSkuRecord> => {
    const data = await this.apiClient.put<BackendlessSkuRecord, BackendlessSkuRecord>(`${urlSubPath}/${kit.objectId}`, kit);
    return data;
  };

  public createSKU = async (sku: BackendlessSkuRecord): Promise<BackendlessSkuRecord> => {
    const data = await this.apiClient.post<BackendlessSkuRecord, BackendlessSkuRecord>(`${urlSubPath}`, sku);
    return data;
  };

  public deleteSKU = async (objectId: string): Promise<void> => {
    await this.apiClient.delete(`${urlSubPath}/${objectId}`);
  };
}
