/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import {
  Button, Form, Input, Select, Space
} from 'antd';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { BackendlessKitRecord, BackendlessSkuRecord } from '../../types';
import SelectSKUs from '../SelectSKUs';

interface Props {
  kit: BackendlessKitRecord;
  onCancel: () => void;
  onSave: (kit: BackendlessKitRecord) => void;
}

interface SkuInputProps {
  value?: BackendlessSkuRecord;
  onChange?: (value: BackendlessSkuRecord) => void;
}

const SkuInput: React.FC<SkuInputProps> = ({ value = undefined, onChange }) => {
  const [sku, setSKU] = useState<BackendlessSkuRecord | undefined>();

  const triggerChange = (changedValue: BackendlessSkuRecord) => {
    onChange?.(changedValue);
  };

  const onSelectSKU = (selectedSku: BackendlessSkuRecord) => {
    setSKU(selectedSku);
    triggerChange(selectedSku);
  };

  return <SelectSKUs sku={value || sku} onSelect={onSelectSKU} placeholder={'Search by SKU.'} />;
};

const KitForm: React.FC<Props> = (props: Props) => {
  const MyDatePicker = generatePicker<Date>(dateFnsGenerateConfig);

  const { kit, onCancel, onSave } = props;

  const onFinish = (values: BackendlessKitRecord) => {
    const updatedKit = { ...kit, ...values };
    onSave(updatedKit);
  };

  return (
    <Form
      name="kitForm"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={kit}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item label="Kit Id" name="kitid">
        <Input data-testid="kit-form-kitid" />
      </Form.Item>

      <Form.Item label="Activation Id" name="activationid">
        <Input data-testid="kit-form-activationid" />
      </Form.Item>

      <Form.Item label="Tracking Number" name="tracking" rules={[{ required: true, message: 'Please input tracking number!' }]}>
        <Input data-testid="kit-form-tracking" />
      </Form.Item>

      <Form.Item label="Shopify Order Number" name="shopify_order_number">
        <Input data-testid="kit-form-shopify_order_number" />
      </Form.Item>

      <Form.Item label="Expires At" name="expiresAt">
        <MyDatePicker data-testid="kit-form-expiresAt" allowClear format='MM/DD/YYYY'/>
      </Form.Item>

      <Form.Item label="Carrier" name="carrier">
        <Select placeholder="Select Carrier" data-testid="kit-form-carrier">
          <Select.Option value="USPS">USPS</Select.Option>
          <Select.Option value="UPS">UPS</Select.Option>
          <Select.Option value="FedEx">FedEx</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name={'sku'} label="Sku">
        <SkuInput />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Space>
          <Button data-testid="kit-form-submit-button" type="primary" htmlType="submit">
            Submit
          </Button>
          <Button data-testid="kit-form-cancel-button" type="primary" onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default KitForm;
