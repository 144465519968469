/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import {
  Button, Divider, Form, Input, Select, Space
} from 'antd';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import TextArea from 'antd/es/input/TextArea';
import { BackendlessOrderRecord, BackendlessSkuRecord } from '../../types';
import SelectSKUs from '../SelectSKUs';
import { BackendlessOrderStatus, BioTouchShippingService } from '../../enums';

interface Props {
  order: BackendlessOrderRecord;
  onCancel: () => void;
  onSave: (order: BackendlessOrderRecord) => void;
  viewMode: boolean;
}

interface SkuInputProps {
  value?: BackendlessSkuRecord;
  onChange?: (value: BackendlessSkuRecord) => void;
}

const SkuInput: React.FC<SkuInputProps> = ({ value = undefined, onChange }) => {
  const [sku, setSKU] = useState<BackendlessSkuRecord | undefined>();

  const triggerChange = (changedValue: BackendlessSkuRecord) => {
    onChange?.(changedValue);
  };

  const onSelectSKU = (selectedSku: BackendlessSkuRecord) => {
    setSKU(selectedSku);
    triggerChange(selectedSku);
  };

  return <SelectSKUs sku={value || sku} onSelect={onSelectSKU} placeholder={'Search by SKU.'} />;
};

const OrderEdit: React.FC<Props> = (props: Props) => {
  const MyDatePicker = generatePicker<Date>(dateFnsGenerateConfig);

  const {
    order, onCancel, onSave, viewMode,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: BackendlessOrderRecord) => {
    const updatedOrder = { ...order, ...values };
    onSave(updatedOrder);
  };

  return (
    <Form
      layout='vertical'
      name="orderForm"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={order}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item label="Bio Touch Id" name="bioTouchId">
        <Input data-testid="order-form-biotouchid" disabled={viewMode}/>
      </Form.Item>

      <Form.Item label="Quantity" name="quantity" rules={[{ required: true, message: 'Please add at least 1!' }]}>
        <Input data-testid="kit-form-quantity" type='number' disabled={viewMode}/>
      </Form.Item>

      <Form.Item label="Shopify Order" name="shopifyOrder">
        <Input data-testid="kit-form-shopifyOrder" disabled={viewMode}/>
      </Form.Item>

      <Form.Item label="Shopify Shipping Service" name="shopifyShippingService">
        <Input data-testid="kit-form-shopifyShippingService" disabled={true}/>
      </Form.Item>

      <Form.Item label="Outbound Carrier" name="outboundCarrier">
        <Select placeholder="Select Outbound Carrier" data-testid="order-form-status" disabled={viewMode}>
          {
            Object.values(BioTouchShippingService).map((shippiment) => <Select.Option key={shippiment} value={shippiment}>{shippiment}</Select.Option>)
          }
        </Select>
      </Form.Item>

      <Form.Item label="Status" name="status">
        <Select placeholder="Select Status" data-testid="order-form-status" disabled={viewMode}>
          <Select.Option value={BackendlessOrderStatus.PENDING}>{BackendlessOrderStatus.PENDING}</Select.Option>
          <Select.Option value={BackendlessOrderStatus.CANCELLED}>{BackendlessOrderStatus.CANCELLED}</Select.Option>
          <Select.Option value={BackendlessOrderStatus.PROCESSING}>{BackendlessOrderStatus.PROCESSING}</Select.Option>
          <Select.Option value={BackendlessOrderStatus.SHIPPED}>{BackendlessOrderStatus.SHIPPED}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name={'sku'} label="Sku">
        <SkuInput />
      </Form.Item>

      <Form.Item label="Biotouch Live Order Sku/Code" name="liveOrderSku">
        <Input data-testid="kit-form-liveOrderSku" disabled={true}/>
      </Form.Item>

      <Divider plain>Patient/Partner Info</Divider>

      <Form.Item label="Customer Name" name="customerName">
        <Input data-testid="order-form-customerName" disabled={viewMode}/>
      </Form.Item>

      <Form.Item label="Customer Address Line 1" name="customerAddressLine1">
        <Input data-testid="order-form-customeraddressline1" disabled={viewMode}/>
      </Form.Item>

      <Form.Item label="Customer Address Line 2" name="customerAddressLine2">
        <Input data-testid="order-form-customeraddressline2" disabled={viewMode}/>
      </Form.Item>

      <Form.Item label="Customer City" name="customerCity">
        <Input data-testid="order-form-customerCity" disabled={viewMode}/>
      </Form.Item>

      <Form.Item label="Customer ZipCode" name="customerZipCode">
        <Input data-testid="order-form-customerZipCode" disabled={viewMode}/>
      </Form.Item>

      <Divider plain>Cancellation</Divider>

      <Form.Item label="Cancelled At" name="cancelledAt">
        <MyDatePicker data-testid="order-form-cancelledAt" allowClear format='MM/DD/YYYY' disabled={viewMode}/>
      </Form.Item>

      <Form.Item label="Reason for cancel" name="reasonForCancel">
        <TextArea data-testid="order-form-reasonForCancel" size='middle' disabled={viewMode}/>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Space>
          <Button disabled={viewMode} loading={loading} data-testid="kit-form-submit-button" type="primary" htmlType="submit" onClick={() => setLoading(true)}>
            Edit
          </Button>
          <Button data-testid="kit-form-cancel-button" type="primary" onClick={onCancel}>
            {viewMode ? 'Close' : 'Cancel'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default OrderEdit;
