import ApiClient, { IApiClient } from './ApiClient';
import { TrackingDataSync } from '../types';

const urlSubPath = '/services';

export default class TrackingApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }

  public dataSync = async (body: TrackingDataSync) => {
    await this.apiClient.post(`${urlSubPath}/Tracking/sync`, body);
  };
}
