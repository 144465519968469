import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/auth';
import ErrorBoundary from './contexts/error-boundary';
import { FeedbackProvider } from './contexts/feedback';
import Root from './routes';
import './styles/normalize.css';

const App: React.FC = () => (
  <>
    <ErrorBoundary>
      <FeedbackProvider>
        <AuthProvider>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </AuthProvider>
      </FeedbackProvider>
    </ErrorBoundary>
  </>
);

export default App;
