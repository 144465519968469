import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

interface SpinnerProps {
  size?: number;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 24 }: SpinnerProps) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />;

  return <Spin data-testid="spinner" indicator={antIcon} />;
};

export default Spinner;
