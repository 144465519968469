import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import BasicTemplate from '../layouts/basic';

interface Props {
  children?: ReactNode;
}

Sentry.init({
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: process.env.REACT_APP_ENVIRONMENT === 'production',
});

const ErrorBoundary: React.FC<Props> = ({ children }: Props) => (
  <Sentry.ErrorBoundary fallback={({ error }) => (
    <BasicTemplate>
      <h1>{`Ops, something went wrong! ${error.cause} ${error.message}`}</h1>
    </BasicTemplate>
  )} showDialog>
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
