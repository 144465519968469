import { Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner';
import Style from './style';

interface LoadingStepsProps {
  percentage: number;
  message?: string;
  containerStyle?: React.CSSProperties
  textStyle?: React.CSSProperties
}

const LoadingSteps = ({
  percentage, message, containerStyle, textStyle,
} : LoadingStepsProps) => {
  const [loadingPercentage, setLoadingPercentage] = useState<number>(percentage);

  useEffect(() => {
    if (percentage) {
      setLoadingPercentage(percentage);
    }
  }, [percentage]);

  return (
    <Style.Container style={containerStyle}>
      <Style.ContainerSpinner>
        <Spinner />
      </Style.ContainerSpinner>
      <Progress data-testid="progress-bar" type="line" percent={loadingPercentage} />
      <Style.Text style={textStyle}>{message}</Style.Text>
    </Style.Container>
  );
};

export default LoadingSteps;
