import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 50%;
  margin: auto;
  height: 300px;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 50px;
`;

export default { Container };
