import ApiClient, { IApiClient } from './ApiClient';
import { BackendlessCarrierRecord } from '../types';

const urlSubPath = '/data/Carriers';

export default class CarrierApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }

  public fetchCarriersCount = async (params: URLSearchParams): Promise<number> => {
    const data = await this.apiClient.get<number>(`${urlSubPath}/count`, { params });
    return data;
  };

  public fetchCarriers = async (params: URLSearchParams): Promise<BackendlessCarrierRecord[]> => {
    const data = await this.apiClient.get<BackendlessCarrierRecord[]>(`${urlSubPath}`, { params });
    return data;
  };

  public updateCarrier = async (kit: BackendlessCarrierRecord): Promise<BackendlessCarrierRecord> => {
    const data = await this.apiClient.put<BackendlessCarrierRecord, BackendlessCarrierRecord>(`${urlSubPath}/${kit.objectId}`, kit);
    return data;
  };

  public createCarrier = async (carrier: BackendlessCarrierRecord): Promise<BackendlessCarrierRecord> => {
    const data = await this.apiClient.post<BackendlessCarrierRecord, BackendlessCarrierRecord>(`${urlSubPath}`, carrier);
    return data;
  };

  public deleteCarrier = async (objectId: string): Promise<void> => {
    await this.apiClient.delete(`${urlSubPath}/${objectId}`);
  };
}
