const BASE_PATH = 'http://api.labelary.com/v1/printers/8dpmm/labels/4x6';

interface ZPLProps {
  zpl: string;
  part: string;
}

const useZPL = ({zpl, part}: ZPLProps) => fetch(`${BASE_PATH}/${part}/${zpl}`, {
  method: 'GET',
  headers: {},
});

export default useZPL;
