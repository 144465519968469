/* eslint-disable @typescript-eslint/no-explicit-any */
// need to refactor this code to remove the api methods
import React, { useState, useEffect, useContext } from 'react';
import authService from '../services/AuthService';

const TOKEN_REF = 'TOKEN';
interface Props {
  children: React.ReactNode;
}
interface AuthProps {
  token: string | null;
  isAuth(): boolean;
}

export const AuthContext = React.createContext<AuthProps | null>(null);

export const AuthProvider = ({ children }: Props) => {
  const [token, setToken] = useState<string | null>(null);

  const updateToken = (_token: string): void => {
    if (!_token) return;
    setToken(_token);
    localStorage.setItem(TOKEN_REF, _token);
  };

  useEffect(() => {
    authService.on('onAutoLogin', () => {
      const storagedToken = localStorage.getItem(TOKEN_REF);
      if (storagedToken && storagedToken !== 'undefined') {
        updateToken(storagedToken);
      }
    });

    authService.on('onLogin', (_token: string) => {
      updateToken(_token);
    });

    authService.on('onLogout', () => {
      setToken(null);
    });

    authService.on('onNoAccessToken', () => {
      setToken(null);
    });

    authService.init();
  });

  const isAuth = () => {
    if (!token) {
      const storagedToken = localStorage.getItem(TOKEN_REF);
      if (storagedToken) {
        updateToken(storagedToken);
        return true;
      }
    } else {
      return true;
    }

    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        isAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthProps {
  const context = useContext(AuthContext) as AuthProps;

  return context;
}
