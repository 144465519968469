import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';

const sentryRelease = process.env.REACT_APP_RELEASE_TAG;
const sentryEnvironment = process.env.REACT_APP_ENVIRONMENT;
Sentry.init({
  dsn: 'https://b474c8b0014441e8b9d401f066696804@o1212911.ingest.sentry.io/4504855416078336',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: !!sentryRelease, // turn off Sentry for local development by default
  release: sentryRelease || '1.0-local-ops-portal',
  environment: sentryEnvironment || 'local',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
