import styled from 'styled-components';
import { Layout, Menu } from 'antd';

const SubMenu = Menu.SubMenu;

const CustomLayout = styled(Layout)`
  min-height: 100vh;
  display: flex;
  flex: 1;
`;

const BrandLogo = styled.div`
  flex: 1;
  color: #fff;
`;

const CustomSubMenu = styled(SubMenu)`
  flex: 1;
  align-self: flex-end;
  max-width: 150px;
`;

const CustomMenuItem = styled(Menu.Item)`
  flex: 1 !important;
  align-self: flex-end;
  max-width: 150px;
`;

export default {
  CustomLayout, BrandLogo, CustomSubMenu, CustomMenuItem,
};
