import React from 'react';
import { Menu, Layout } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import Styled from './styles';
import authService from '../../services/AuthService';

const { Header, Content, Footer } = Layout;

interface Props {
  children: React.ReactNode;
  active: string;
}

const AuthTemplate = ({ children, active }: Props) => (
  <Styled.CustomLayout>
    <Header data-testid="header-div">
      <Menu style={{ flex: 'auto', minWidth: 0 }} theme="dark" mode="horizontal" selectedKeys={[active]} defaultSelectedKeys={['1']}>
        <Styled.BrandLogo> imaware </Styled.BrandLogo>
        <Styled.CustomSubMenu title="Kit Building" key="kit-sub">
          <Menu.Item data-testid="menu-item">
            <a href={'/print'} target="_self">
                Print Shipping Labels
            </a>
          </Menu.Item>
          <Menu.Item data-testid="menu-item">
            <a href={'/bulk'} target="_self">
                Build Bulk Order
            </a>
          </Menu.Item>
          <Menu.Item data-testid="menu-item">
            <a href={'/qc'} target="_self">
                Quality Control
            </a>
          </Menu.Item>
        </Styled.CustomSubMenu>
        <Styled.CustomMenuItem key="order-sub">
          <a href={'/orders'} target="_self" style={{flex: 1}}>
                Orders
          </a>
        </Styled.CustomMenuItem>
        <Styled.CustomSubMenu title="Fullfilment" key="fullfilment-sub">
          <Menu.Item data-testid="menu-item">
            <a href={'/shopify'} target="_self">
                Associate Shopify Number
            </a>
          </Menu.Item>
          <Menu.Item data-testid="menu-item">
            <a href={'/tracking'} target="_self">
                Associate Return Tracking Number
            </a>
          </Menu.Item>
        </Styled.CustomSubMenu>
        <Styled.CustomSubMenu title="Management" key="manage-sub">
          <Menu.Item data-testid="menu-item">
            <a href={'/'} target="_self">
                SKUs
            </a>
          </Menu.Item>
          <Menu.Item data-testid="menu-item">
            <a href={'/kits'} target="_self">
                Kits
            </a>
          </Menu.Item>
        </Styled.CustomSubMenu>
        <Styled.CustomSubMenu icon={<SettingOutlined />} title="Settings" key="setting-sub">
          <Menu.Item key="setting">
            <a onClick={() => {
              authService.logout();
            }}>Signout</a>
          </Menu.Item>
        </Styled.CustomSubMenu>
      </Menu>
    </Header>
    <Content style={{ padding: '0 50px' }}>
      <div className="site-layout-content">{children}</div>
    </Content>
    <Footer data-testid="footer-div" style={{ textAlign: 'center' }}>
        Copyright ©2024. imaware
    </Footer>
  </Styled.CustomLayout>
);

export default AuthTemplate;
