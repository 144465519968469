import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const onRedirect = () => {
    navigate('/');
  };
  return (
    <Result
      status="404"
      title="Not Found"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={onRedirect}>
          Back Home
        </Button>
      }
    />
  );
};
export default NotFoundPage;
