import ApiClient, { IApiClient } from './ApiClient';
import { BackendlessKitRecord, BackendlessQualityControlRecord, BackendlessUserRecord } from '../types';

const urlSubPath = '/data/QualityControl';

export default class QualityControlApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }

  public fetchQualityControlCount = async (params?: URLSearchParams): Promise<number> => {
    const data = await this.apiClient.get<number>(`${urlSubPath}/count`, { params });
    return data;
  };

  public fetchQualityControl = async (params?: URLSearchParams): Promise<BackendlessQualityControlRecord[]> => {
    const data = await this.apiClient.get<BackendlessQualityControlRecord[]>(`${urlSubPath}`, { params });
    return data;
  };

  public fetchQualityControlById = async (objectId: string): Promise<BackendlessQualityControlRecord> => {
    const params = new URLSearchParams();
    const where = `objectId = '${objectId}'`;
    params.set('where', where);
    params.set('loadRelations', 'kitId,user');
    params.set('relationsDepth', '3');
    const data = await this.apiClient.get<BackendlessQualityControlRecord[]>(`${urlSubPath}`, { params });
    return data[0];
  };

  public updateQualityControl = async (quality: BackendlessQualityControlRecord): Promise<BackendlessQualityControlRecord> => {
    const data = await this.apiClient.put<BackendlessQualityControlRecord, BackendlessQualityControlRecord>(`${urlSubPath}/${quality.objectId}`, quality);
    return data;
  };

  public createQualityControl = async (quality: BackendlessQualityControlRecord): Promise<BackendlessQualityControlRecord> => {
    const data = await this.apiClient.post<BackendlessQualityControlRecord, BackendlessQualityControlRecord>(`${urlSubPath}`, quality);
    return data;
  };

  public addKitOnQualityControlRecord = async (objectId: string, kit: BackendlessKitRecord): Promise<BackendlessQualityControlRecord> => {
    const data = await this.apiClient.post<string[], BackendlessQualityControlRecord>(`${urlSubPath}/${objectId}/kitId`, [kit.objectId]);
    return data;
  };

  public addUserRelation = async (objectId: string, user: BackendlessUserRecord): Promise<BackendlessQualityControlRecord> => {
    const data = await this.apiClient.post<string[], BackendlessQualityControlRecord>(`${urlSubPath}/${objectId}/user`, [user.objectId]);
    return data;
  };

  public deleteQualityControl = async (objectId: string): Promise<void> => {
    await this.apiClient.delete(`${urlSubPath}/${objectId}`);
  };
}
