import React from 'react';
import {
  Button, Checkbox, Form, Input
} from 'antd';
import { IPrintLabels } from '../../../../types';

interface PrintLabelNumberFormProps {
  onConfirm(params: IPrintLabels): void;
}

const PrintLabelNumberForm = ({ onConfirm }: PrintLabelNumberFormProps) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} layout='vertical' onFinish={onConfirm}>
      <Form.Item label='Number of Labels' name='numberOfLabels'>
        <Input data-testid='number-labels-input' type='number'/>
      </Form.Item>
      <Form.Item name='isPrintingShippingLabel' valuePropName="checked">
        <Checkbox data-testid='shipping-label-checkbox'>Return Shipping Label</Checkbox>
      </Form.Item>
      <Form.Item name='isPrintingKitLabel' valuePropName="checked">
        <Checkbox data-testid='kit-label-checkbox'>Kit ID label</Checkbox>
      </Form.Item>
      <Form.Item>
        <Button data-testid='submit-btn'
          htmlType='submit'
          style={{marginTop: 30}}
          size='large'
          type='primary'>
                    Print Labels
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PrintLabelNumberForm;
