import React, { useEffect, useState } from 'react';
import {
  Button, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import { EditFilled, CloseCircleFilled, DeleteFilled } from '@ant-design/icons';
import { format } from 'date-fns';
import SearchHeader from '../../components/SearchHeader';
import { useFeedback } from '../../contexts/feedback';
import { BackendlessKitRecord } from '../../types';
import { kitApi, trackingApi } from '../../api';
import KitForm from '../../components/KitForm';
import AuthTemplate from '../../layouts/auth';

const newKit = {
  kitid: '',
  objectId: '',
  created: '',
  ownerId: '',
  tracking: '',
  printed: false,
  carrier: '',
  shopify_order_number: '',
  updated: '',
  activationid: '',
};

const KitIdsPage: React.FC = () => {
  const { addMessage } = useFeedback();

  const [data, setData] = useState<Array<BackendlessKitRecord> | []>([]);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [kitModal, setKitModal] = useState<{ open: boolean; kit: BackendlessKitRecord }>({
    open: false,
    kit: newKit as unknown as BackendlessKitRecord,
  });

  // eslint-disable-next-line max-len
  const whereQuery = (searchTerm: string) =>
    // eslint-disable-next-line max-len, implicit-arrow-linebreak
    `kitid like '%${searchTerm}%' or activationid like '%${searchTerm}%' or tracking like '%${searchTerm}%' or shopify_order_number like '%${searchTerm}%' or carrier like '%${searchTerm}%' or sku.lab_prefix like '%${searchTerm}%' or sku.sku like '%${searchTerm}%'`;
  const onFetchData = async (params: URLSearchParams) => {
    params.set('loadRelations', 'sku.lab');
    params.set('sortBy', 'created DESC');
    const itemsTotalCount = await kitApi.fetchKitsCount(params);
    const items = await kitApi.fetchKits(params);

    if (items) {
      setData([...items]);
      setTotal(itemsTotalCount);
    }
  };

  useEffect(() => {
    if (data?.length === 0) {
      const params = new URLSearchParams();
      params.append('pageSize', '10');
      params.append('offset', '0');
      onFetchData(params);
    }
  }, []);

  const onAdd = () => {
    setKitModal({
      open: true,
      kit: newKit as unknown as BackendlessKitRecord,
    });
  };

  const onEdit = (record: BackendlessKitRecord) => {
    setKitModal({
      open: true,
      kit: record,
    });
  };

  const onDelete = async (kit: BackendlessKitRecord) => {
    try {
      await kitApi.deleteKit(kit.objectId);
      const index = data.findIndex(
        (dataRecord) => dataRecord.objectId === kit.objectId
      );
      data.splice(index, 1);
      setData([...data]);
      addMessage({
        severity: 'success',
        content: 'Kit deleted successsfully!',
      });
    } catch {
      addMessage({
        severity: 'error',
        content: 'Error deleting Kit',
      });
    }
  };

  const onSearch = async (searchTerm: string) => {
    const params = new URLSearchParams();
    params.append('pageSize', '10');
    params.append('offset', '0');
    params.append('where', whereQuery(searchTerm).toString());
    onFetchData(params);
    setSearch(searchTerm);
  };

  const onClearSearch = () => {
    const params = new URLSearchParams();
    params.append('pageSize', '10');
    params.append('offset', '0');
    onFetchData(params);
  };

  const onCancel = () => {
    setKitModal({
      open: false,
      kit: newKit as unknown as BackendlessKitRecord,
    });
  };

  const onChangePagination = (page: number, pagesize: number) => {
    const offset = (page - 1) * pagesize;
    const params = new URLSearchParams();
    params.append('pageSize', pagesize.toString());
    params.append('offset', offset.toString());

    if (search) {
      params.append('where', whereQuery(search).toString());
    }

    onFetchData(params);
  };

  const onCancelKit = async (kit: BackendlessKitRecord) => {
    try {
      const updated = {
        ...kit,
        kitid: `CANCEL-${kit.kitid}`,
      } as BackendlessKitRecord;

      await kitApi.updateKit(updated);

      const index = data.findIndex(
        (record) => record.objectId === kit.objectId
      );
      data[index] = {
        ...data[index],
        ...updated,
      };
      setData([...data]);
      addMessage({
        severity: 'success',
        content: 'Kit was cancelled successfully',
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response && e.response.data) {
        addMessage({
          severity: 'error',
          content: `Error cancelling Kit - ${e.response.data.code} ${e.response.data.message}`,
        });
      }
    }
  };

  const onSave = async (kit: BackendlessKitRecord) => {
    try {
      if (!kit.objectId) {
        // create
        const res = await kitApi.createKit({
          kitid: kit.kitid,
          activationid: kit.activationid ? kit.activationid : null,
          tracking: kit.tracking,
          shopify_order_number: kit.shopify_order_number,
          carrier: kit.carrier,
        } as BackendlessKitRecord);

        if (kit.sku) {
          await kitApi.addSkuRelation(res.objectId, kit.sku);
        }

        setData([{...res, ...kit}, ...data]);
        addMessage({
          severity: 'success',
          content: 'Kit was created successfully',
        });
      } else {
        await kitApi.updateKit(kit);

        if (kit.sku) {
          await kitApi.addSkuRelation(kit.objectId, kit.sku);
        }

        const index = data.findIndex(
          (record) => record.objectId === kit.objectId
        );
        data[index] = {
          ...data[index],
          ...kit,
        };
        setData([...data]);
        addMessage({
          severity: 'success',
          content: 'Kit was updated successfully',
        });

        if (kit.carrier && kit.shopify_order_number && kit.tracking) {
          // call sync again
          await trackingApi.dataSync({
            shopify_order_id: kit.shopify_order_number, kit_id: kit.kitid, return_tracking_number: kit.tracking, carrier: kit.carrier,
          });
        }
      }
      setKitModal({
        open: false,
        kit: newKit as unknown as BackendlessKitRecord,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response && e.response.data) {
        addMessage({
          severity: 'error',
          content: `Error updating Kit - ${e.response.data.code} ${e.response.data.message}`,
        });
      }
    }
  };

  const columns = [
    {
      title: 'Kit Id',
      dataIndex: 'kitid',
    },
    {
      title: 'Activation Id',
      dataIndex: 'activationid',
    },
    {
      title: 'Sku',
      dataIndex: ['sku', 'sku'],
    },
    {
      title: 'Tracking',
      dataIndex: 'tracking',
      ellipsis: true,
    },
    {
      title: 'Printed',
      dataIndex: 'printed',
      render: (value: boolean) => <Tag color={value ? 'green' : 'red'}> {value ? 'YES' : 'NO'} </Tag>,
    },
    {
      title: 'Shopify Order Number',
      dataIndex: 'shopify_order_number',
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
    },
    {
      title: 'Expires At',
      dataIndex: 'expiresAt',
      render: (value: Date) => (value ? `${format(value, 'MM/dd/yyyy')}` : ''),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_: unknown, record: BackendlessKitRecord) => (
        <span data-testid="non-selected-action">
          <Tooltip title="Edit Kit">
            <Button data-testid="edit-action" style={{ marginRight: 10 }} onClick={() => onEdit(record)} type="primary" shape="circle" icon={<EditFilled />} />
          </Tooltip>
          <Tooltip title="Delete Kit">
            <Popconfirm title="Delete Kit" description="Are you sure to delete this Kit?" onConfirm={() => onDelete(record)}>
              <Button data-testid="delete-action" style={{ marginRight: 10 }} danger type="primary" shape="circle" icon={<DeleteFilled />} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Cancel Kit">
            <Popconfirm title="Cancel Kit" description="Are you sure to cancel this Kit?" onConfirm={() => onCancelKit(record)}>
              <Button data-testid="cancel-action" danger type="primary" shape="circle" icon={<CloseCircleFilled />} />
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];
  return (
    <AuthTemplate active='/kits'>
      <h1>Kits</h1>
      <SearchHeader searchPlaceholder='Search Kits' onAdd={onAdd} onSearch={onSearch} onClearSearch={onClearSearch} />
      <Table
        bordered
        dataSource={data}
        columns={columns}
        pagination={{
          total,
          onChange: onChangePagination,
          showSizeChanger: false,
        }}
      />
      <Modal destroyOnClose title={kitModal.kit.kitid ? 'Edit Kit' : 'Create Kit'} footer={null} open={kitModal.open} onCancel={onCancel}>
        <KitForm kit={kitModal.kit} onCancel={onCancel} onSave={onSave} />
      </Modal>
    </AuthTemplate>
  );
};
export default KitIdsPage;
