import ApiClient, { IApiClient } from './ApiClient';
import {
  BackendlessOrderRecord, BackendlessSkuRecord, LogisticsAuth
} from '../types';

const urlSubPath = '/data/Order';

export default class OrderApi {
  private apiClient: IApiClient;

  private w3Client: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
    this.w3Client = new ApiClient(`${process.env.REACT_APP_URL_IMAWARE_LOGISTICS}/`);
  }

  private authW3 = async (): Promise<LogisticsAuth> => {
    const data = await this.w3Client.post('/auth/credentials', {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    });
    return data as LogisticsAuth;
  };

  public fetchOrdersCount = async (params: URLSearchParams): Promise<number> => {
    const data = await this.apiClient.get<number>(`${urlSubPath}/count`, { params });
    return data;
  };

  public fetchOrders = async (params: URLSearchParams): Promise<BackendlessOrderRecord[]> => {
    const data = await this.apiClient.get<BackendlessOrderRecord[]>(`${urlSubPath}`, { params });
    return data;
  };

  public fetchOrdersByStatus = async (status: string): Promise<BackendlessOrderRecord[]> => {
    const params = new URLSearchParams();
    const where = `status = '${status}'`;
    params.set('where', where);
    params.set('loadRelations', 'sku.lab');
    params.set('sortBy', 'created DESC');
    const data = await this.apiClient.get<BackendlessOrderRecord[]>(`${urlSubPath}`, { params });
    return data;
  };

  public updateOrder = async (order: BackendlessOrderRecord): Promise<BackendlessOrderRecord> => {
    const data = await this.apiClient.put<BackendlessOrderRecord, BackendlessOrderRecord>(`${urlSubPath}/${order.objectId}`, order);
    return data;
  };

  public addSkuRelation = async (objectId: string, sku: BackendlessSkuRecord): Promise<BackendlessOrderRecord> => {
    const data = await this.apiClient.post<string[], BackendlessOrderRecord>(`${urlSubPath}/${objectId}/sku`, [sku.objectId]);
    return data;
  };

  public startFulfillment = async (dto: BackendlessOrderRecord) => {
    const { accessToken } = await this.authW3();

    const logisticsObject = {
      id: dto.id,
      shippingService: dto.outboundCarrier,
    } as Partial<BackendlessOrderRecord>;

    if (dto.liveOrderSku) {
      logisticsObject.liveOrderSku = dto.liveOrderSku;
    }
    const data = await this.w3Client.post('/orders/logistics', logisticsObject, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      timeout: 300000,
    });
    return data;
  };
}
