import React from 'react';
import { Menu, Layout } from 'antd';
import Styled from './styles';

const { Header } = Layout;

interface Props {
  children: React.ReactNode;
}

const BasicLayout = ({ children }: Props) => (
  <Styled.CustomLayout>
    <Header data-testid="header-basictemplate" className="header">
      <Menu data-testid="menu-basictemplate" theme="dark" mode="horizontal" />
    </Header>
    {children}
  </Styled.CustomLayout>
);

export default BasicLayout;
