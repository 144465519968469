import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card, Checkbox, Form, Input, List, Modal
} from 'antd';
import { format } from 'date-fns';
import TextArea from 'antd/es/input/TextArea';
import { CloseCircleOutlined } from '@ant-design/icons';
import AuthTemplate from '../../layouts/auth';
import { BackendlessQualityControlRecord, BackendlessUserRecord } from '../../types';
import { qualityControlApi, userApi } from '../../api';
import { useFeedback } from '../../contexts/feedback';
import { useNormalization } from '../../hooks/useNormalization';
import { QualityControlStatus } from '../../utils/QualityControlStatusEnum';

const ENTER = 13;
const QualityControlDetails = () => {
  const { showLoadingScreen, addMessage } = useFeedback();
  const { activationIdNormalization } = useNormalization();

  const navigate = useNavigate();
  const { objectId } = useParams();
  const [form] = Form.useForm();

  const [qualityControl, setQualityControl] = useState<BackendlessQualityControlRecord>();
  const [loggedUser, setLoggedUser] = useState<BackendlessUserRecord | null>();
  const [errors, setErrors] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchQualityControlById = async () => {
    if (objectId) {
      const qcRecord = await qualityControlApi.fetchQualityControlById(objectId);
      if (qcRecord) {
        setQualityControl(qcRecord);
        if (qcRecord.user) {
          setLoggedUser(qcRecord.user);
        } else {
          await fetchUser();
        }
        form.setFieldsValue(qcRecord);
      }
    }
  };

  const fetchUser = async () => {
    const user = await userApi.fetchUserByToken();
    setLoggedUser(user);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (qualityControl && loggedUser) {
        const _errors = validations();
        if (_errors) {
          // save it
          qualityControl.issues = _errors.toString();
          qualityControl.numberOfIssues = _errors.length;
          // add relation
          await qualityControlApi.addUserRelation(qualityControl.objectId, loggedUser);
          qualityControl.user = loggedUser;
        }
        setOpenModal(true);
      }
    } catch (e) {
      if (e instanceof Error) {
        addMessage({ severity: 'error', content: `Error saving the user ${e.toString()}}`});
      }
    }

    setLoading(false);
  };

  const onSave = async () => {
    showLoadingScreen(true);
    const {
      scanSleeveKitId, scanCollectionDeviceKitId, scanReturnLabTracking, scanInputActivationCode, scanActivationUrl, visualInspection,
    } = form.getFieldsValue();

    if (qualityControl && loggedUser) {
      qualityControl.notes = notes;
      qualityControl.scanActivationUrl = scanActivationUrl;
      qualityControl.scanSleeveKitId = scanSleeveKitId;
      qualityControl.scanCollectionDeviceKitId = scanCollectionDeviceKitId;
      qualityControl.scanReturnLabTracking = scanReturnLabTracking;
      qualityControl.scanInputActivationCode = scanInputActivationCode;
      qualityControl.visualInspection = visualInspection;
      qualityControl.status = QualityControlStatus.COMPLETED;
      await qualityControlApi.updateQualityControl(qualityControl);
      showLoadingScreen(false);
    }
    navigate('/qc');
  };

  const validations = (): string[] | undefined => {
    const _errors = [];
    if (qualityControl && qualityControl.kitId) {
      const {
        scanSleeveKitId, scanCollectionDeviceKitId, scanReturnLabTracking, scanInputActivationCode, scanActivationUrl,
      } = form.getFieldsValue();

      const {
        kitid, activationid, tracking, sku,
      } = qualityControl.kitId;

      if (kitid !== scanSleeveKitId) {
        _errors.push('Sleeve kit id did not match');
      }
      if (kitid !== scanCollectionDeviceKitId) {
        _errors.push('Collection device kit id did not match');
      }
      if (activationid && activationid !== activationIdNormalization(scanInputActivationCode)) {
        _errors.push('Activation code did not match');
      }
      if (scanReturnLabTracking) {
        if (!scanReturnLabTracking.includes(tracking)) {
          _errors.push('Return to lab tracking did not match');
        }
      }
      if (sku) {
        if (scanActivationUrl && sku.partner?.activation_url) {
          const { partner } = sku;
          const partnerUrl = partner.activation_url;
          const splitPartner = partnerUrl.split('?');
          const splitTyped = scanActivationUrl.split('?');
          if (splitTyped[0] !== splitPartner[0]) {
            _errors.push('Activation URL did not match');
          }
        }
      }

      setErrors(_errors);
    }
    return _errors;
  };

  useEffect(() => {
    if (objectId) {
      // fetch qc record
      fetchQualityControlById();
    }
  }, []);

  return (
    <AuthTemplate active='/qc'>
      <h1>Quality Control</h1>
      <Card>
        <p><strong>Kit Id</strong>   -   <span>{qualityControl?.kitId.kitid}</span></p>
        <p><strong>Activation Code</strong>   -   <span>{qualityControl?.kitId.activationid}</span></p>
        <p><strong>User</strong>   -   <span>{loggedUser?.name}</span></p>
        { qualityControl?.created && <p><strong>Date</strong>   -   <span>{format(qualityControl?.created, 'MM/dd/yyyy')}</span></p> }
      </Card>

      <h2>Scan</h2>
      <Form name='qcForm' form={form} layout='vertical' onFinish={onSubmit}>
        <Form.Item name='scanSleeveKitId' required label="Sleeve Kit Id">
          <Input onKeyDown={(e) => (e.keyCode === ENTER ? e.preventDefault() : '')}/>
        </Form.Item>
        <Form.Item name='scanActivationUrl' required label="Activation URL">
          <Input onKeyDown={(e) => (e.keyCode === ENTER ? e.preventDefault() : '')}/>
        </Form.Item>
        <Form.Item name='scanCollectionDeviceKitId' required label="Collection Device Kit Id">
          <Input onKeyDown={(e) => (e.keyCode === ENTER ? e.preventDefault() : '')}/>
        </Form.Item>
        <Form.Item name='scanReturnLabTracking' required label="Return to Lab tracking">
          <Input onKeyDown={(e) => (e.keyCode === ENTER ? e.preventDefault() : '')}/>
        </Form.Item>
        <Form.Item name='scanInputActivationCode' required label="Activation Code">
          <Input onKeyDown={(e) => (e.keyCode === ENTER ? e.preventDefault() : '')}/>
        </Form.Item>
        <Form.Item name='visualInspection' required label="Visual Inspection" valuePropName="checked">
          <Checkbox >Any missing components?</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button data-testid="submit-btn"
            disabled={qualityControl?.status === QualityControlStatus.COMPLETED}
            loading={loading}
            htmlType="submit"
            style={{marginTop: 30}}
            block
            type="primary">
            Validate
          </Button>
        </Form.Item>
      </Form>
      <Modal destroyOnClose title={errors.length > 0 ? `${errors.length} Error(s) Found` : 'No Issues Found'} footer={null} open={openModal} onCancel={() => setOpenModal(false)}>
        {
          errors.length > 0 && <div>
            <List
              dataSource={errors}
              renderItem={(item) => (
                <List.Item>
                  <CloseCircleOutlined /> {item}
                </List.Item>
              )}>
            </List>
            <p> Please resolve these issues and click in continue</p>
            <Form.Item label="Notes">
              <TextArea
                rows={4}
                placeholder="Any further rework required, issue diagnosis, etc"
                onChange={({target: { value }}) => setNotes(value)}/>
            </Form.Item>
          </div>
        }
        {
          (!errors || errors.length === 0) && <div>
            <h1>No Issues Found</h1>
          </div>
        }
        <Button
          onClick={onSave}
          style={{marginTop: 30}}
          block
          type="primary">
          Continue
        </Button>
      </Modal>
    </AuthTemplate>
  );
};

export default QualityControlDetails;
