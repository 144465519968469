/* eslint-disable no-shadow */
import React, { useEffect } from 'react';

import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import BasicTemplate from '../../layouts/basic';
import Custom from './styles';
import { LoginProps } from '../../types';
import { useAuth } from '../../contexts/auth';
import { useFeedback } from '../../contexts/feedback';
import authService from '../../services/AuthService';

const Login = () => {
  const { isAuth } = useAuth();
  const feedback = useFeedback();
  const navigate = useNavigate();

  const onFinish = async (values: LoginProps) => {
    try {
      feedback.showLoadingScreen(true);
      await authService.signIn(values.username, values.password);
      navigate('/');
    } catch (e) {
      Sentry.captureException(e);
      feedback.addMessage({
        severity: 'error',
        content: 'Error during login. Please try again',
      });
    }
    feedback.showLoadingScreen(false);
  };

  const onFinishFailed = () => {
    feedback.addMessage({
      severity: 'error',
      content: 'Please, fill out the Username and Password!',
    });
  };

  useEffect(() => {
    if (isAuth()) {
      navigate('/');
    }
  }, []);

  return (
    <BasicTemplate>
      <Custom.Container>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input data-test-id="username-input" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password data-test-id="pw-input" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              data-test-id="submit-btn"
              id="submit-btn"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Custom.Container>
    </BasicTemplate>
  );
};

export default Login;
