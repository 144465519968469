import styled from 'styled-components';
import { Layout } from 'antd';

const CustomLayout = styled(Layout)`
  min-height: 100vh;
  display: flex;
  flex: 1;
`;

export default { CustomLayout };
