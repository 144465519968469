import React from 'react';
import { Input } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';

const { Search } = Input;

interface SearchBarParams {
  isLoading: boolean;
  size: SizeType;
  placeholder: string;
  onSearch(props: string): void;
  onClearSearch(): void;
}

const SearchBar = ({
  isLoading,
  size,
  onSearch,
  placeholder,
  onClearSearch,
}: SearchBarParams) => (
  <Search
    onSearch={onSearch}
    onChange={(event) => {
      if (event.target.value === '') onClearSearch();
    }}
    placeholder={placeholder}
    enterButton="Search"
    size={size}
    loading={isLoading}
  />
);

export default SearchBar;
