import ApiClient, { IApiClient } from './ApiClient';
import { IBackendlessCloudCreateShippingLabelDTO } from '../types';

const cloudFunctionsSubPath = '/services/Shipping';

export default class ShippingApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }
  // cloud functions

  public createShippingLabel = async (dto: IBackendlessCloudCreateShippingLabelDTO): Promise<string[]> => {
    const data = await this.apiClient.post<IBackendlessCloudCreateShippingLabelDTO, string[]>(`${cloudFunctionsSubPath}/createShippingLabel`, dto);
    return data;
  };
}
