export function setItem(itemName: string, value: string): void {
  localStorage.setItem(itemName, value);
}

export function getItem(itemName: string): string | null {
  return localStorage.getItem(itemName);
}

export function removeItem(itemName: string): void {
  localStorage.removeItem(itemName);
}
