import React, { useEffect, useState } from 'react';
import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import AuthTemplate from '../../layouts/auth';
import { qualityControlApi } from '../../api';
import { BackendlessQualityControlRecord } from '../../types';
import { QualityControlStatus } from '../../utils/QualityControlStatusEnum';

const PAGE_SIZE = 10;

const QualityControl = () => {
  const navigate = useNavigate();
  const [qualityControlList, setQualityControlList] = useState<BackendlessQualityControlRecord[] | []>([]);
  const [total, setTotal] = useState<number>(0);

  const fetchQualityControlRecords = async (offset = 0) => {
    const params = new URLSearchParams();
    params.set('loadRelations', 'kitId,user');
    params.set('relationsDepth', '1');
    params.set('sortBy', 'created desc');
    params.append('pageSize', `${PAGE_SIZE}`);
    params.append('offset', `${offset}`);
    const records = await qualityControlApi.fetchQualityControl(params);
    if (offset === 0) {
      // calc total first time call
      const numberOfRecords = await qualityControlApi.fetchQualityControlCount();
      setTotal(numberOfRecords);
    }

    setQualityControlList([...records]);
  };

  const onChangePagination = (page: number) => {
    const offset = (page - 1) * PAGE_SIZE;
    fetchQualityControlRecords(offset);
  };

  useEffect(() => {
    fetchQualityControlRecords();
  }, []);

  const columns: ColumnsType<BackendlessQualityControlRecord> = [
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: 'Incomplete',
          value: QualityControlStatus.INCOMPLETE,
        },
        {
          text: 'Complete',
          value: QualityControlStatus.COMPLETED,
        },
      ],
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value: string | number | boolean, record: BackendlessQualityControlRecord) => record.status.includes(value.toString()),
      render: (value) => <Tag color={value === QualityControlStatus.COMPLETED ? 'green' : 'red'}> {value} </Tag>,
      width: '10%',
    },
    {
      title: 'Kit Id',
      dataIndex: 'kitId',
      render: (k) => `${k.kitid}`,
    },
    {
      title: 'Activation Id',
      dataIndex: 'kitId',
      render: (k) => k?.activationid,
    },
    {
      title: 'Date',
      dataIndex: 'created',
      render: (value) => `${format(value, 'MM/dd/yyyy')}`,
    },
    {
      title: 'User',
      dataIndex: 'user',
      render: (value) => `${value ? value.name : ''}`,
    },
    {
      title: 'Number of Issues',
      dataIndex: 'numberOfIssues',
    },
    {
      title: 'Issues Found',
      dataIndex: 'issues',
      ellipsis: true,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      ellipsis: true,
    },
    {
      title: 'Scan - Sleeve Kit ID',
      dataIndex: 'scanSleeveKitId',
      ellipsis: true,
    },
    {
      title: 'Scan - Activation URL',
      dataIndex: 'scanActivationUrl',
      ellipsis: true,
    },
    {
      title: 'Scan - Collection Device Kit ID',
      dataIndex: 'scanCollectionDeviceKitId',
      ellipsis: true,
    },
    {
      title: 'Scan - Return to Lab Tracking',
      dataIndex: 'scanReturnLabTracking',
      ellipsis: true,
    },
    {
      title: 'Input Activation Code',
      dataIndex: 'scanInputActivationCode',
    },
    {
      title: 'Visual Inspection',
      dataIndex: 'visualInspection',
      render: (value) => <Tag color={value ? 'green' : 'red'}> {value ? 'YES' : 'NO'} </Tag>,
    },
    {
      title: 'Actions',
      render: (value) => (
        <Space size="middle">
          <a onClick={() => navigate(`/qc/${value.objectId}`)}>View</a>
        </Space>
      ),
    },
  ];
  return (
    <AuthTemplate active='/qc'>
      <h1>Quality Control</h1>
      <Table
        columns={columns}
        dataSource={qualityControlList}
        pagination={{
          pageSize: PAGE_SIZE,
          total,
          onChange: onChangePagination,
          showSizeChanger: false,
        }}/>
    </AuthTemplate>
  );
};

export default QualityControl;
