import ApiClient, { IApiClient } from './ApiClient';
import { BackendlessLabRecord } from '../types';

const urlSubPath = '/data/Labs';

export default class LabApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }

  public fetchLabsCount = async (params?: URLSearchParams): Promise<number> => {
    const data = await this.apiClient.get<number>(`${urlSubPath}/count`, { params });
    return data;
  };

  public fetchLabs = async (params?: URLSearchParams): Promise<BackendlessLabRecord[]> => {
    const data = await this.apiClient.get<BackendlessLabRecord[]>(`${urlSubPath}`, { params });
    return data;
  };

  public updateLab = async (kit: BackendlessLabRecord): Promise<BackendlessLabRecord> => {
    const data = await this.apiClient.put<BackendlessLabRecord, BackendlessLabRecord>(`${urlSubPath}/${kit.objectId}`, kit);
    return data;
  };

  public createLab = async (lab: BackendlessLabRecord): Promise<BackendlessLabRecord> => {
    const data = await this.apiClient.post<BackendlessLabRecord, BackendlessLabRecord>(`${urlSubPath}`, lab);
    return data;
  };

  public deleteLab = async (objectId: string): Promise<void> => {
    await this.apiClient.delete(`${urlSubPath}/${objectId}`);
  };
}
