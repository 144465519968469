import React from 'react';
import Spinner from '../Spinner';
import './index.css';

const LoadingScreen: React.FC = () => (
  <div className="screen-container">
    <Spinner />
  </div>
);

export default LoadingScreen;
