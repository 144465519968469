/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

export enum BackendlessOrderStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SHIPPED = 'SHIPPED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
}

export enum BioTouchShippingService {
  USPS_FIRST_ENDICIA = 'USPS First-Class Mail (Endicia)',
  USPS_PARCEL_ENDICIA = 'USPS Parcel Post (Endicia)',
  USPS_PARCEL_NON_PRESORT = 'USPS Parcel Select Non-presort ',
  USPS_PRIORITY_ENDICIA = 'USPS Priority Mail (Endicia)',
  USPS_FIRST_INTERNATIONAL = 'USPS First-Class International',
  UPS_NEXT_DAY_AIR_EARLY = 'UPS Next Day Air Early A.M.',
  UPS_NEXT_DAY_AIR_EARLY_SATURDAY_DELIVERY = 'UPS Next Day Air Early A.M.-Saturday Delivery',
  UPS_NEXT_DAY_AIR = 'UPS Next Day Air',
  UPS_NEXT_DAY_AIR_SATURDAY = 'UPS Next Day Air-Saturday Delivery',
  UPS_NEXT_DAY_AIR_SAVER = 'UPS Next Day Air Saver',
  UPS_GROUND = 'UPS Ground',
  UPS_SECOND_DAY_AIR_AM = 'UPS 2nd Day Air A.M.',
  UPS_SECOND_DAY_AIR = 'UPS 2nd Day Air',
  UPS_THIRD_DAY_SELECT = 'UPS 3 Day Select',
  UPS_EXPEDITED = 'UPS Expedited',
  UPS_WORLDWIDE_EXPEDITED = 'UPS Worldwide Expedited',
  UPS_INTERNATIONAL_GROUND = 'UPS Int’l Ground (Can/Mex)',
  FEDEX_GROUND = 'FedEx Ground',
}
