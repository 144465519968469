import ApiClient, { IApiClient } from './ApiClient';
import { BackendlessUserRecord } from '../types';

const urlSubPath = '/services/UserHelper';

export default class UserApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }

  public fetchUserByToken = async (): Promise<BackendlessUserRecord> => {
    const data = await this.apiClient.get<BackendlessUserRecord>(`${urlSubPath}/retrieveUser`);
    return data;
  };
}
