import styled from 'styled-components';

const Container = styled.div`
  width: 50%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const ContainerSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

const Text = styled.h4`
  text-align: center
`;

export default { Container, ContainerSpinner, Text };
