/* eslint-disable no-shadow */
import React, { useState, useContext } from 'react';
import { message } from 'antd';
import { NoticeType } from 'antd/es/message/interface';
import LoadingScreen from '../components/LoadingScreen';

interface IMessage {
  key?: number;
  severity: 'info' | 'success' | 'error' | 'warning' | 'loading';
  content: string;
}

interface FeedbackProviderProps {
  children: React.ReactNode;
}

interface IFeedbackContext {
  addMessage: (message: IMessage) => void;
  showLoadingScreen: (show: boolean) => void;
}

export const FeedbackContext = React.createContext<IFeedbackContext | null>(
  null
);

export const FeedbackProvider: React.FC<FeedbackProviderProps> = ({
  children,
}: FeedbackProviderProps) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const addMessage = (message: IMessage) => {
    messageApi.open({
      key: new Date().getTime(),
      type: message.severity as NoticeType,
      content: message.content,
      duration: 5,
    });
  };

  const showLoadingScreen = (show: boolean) => {
    setIsLoading(show);
  };

  const feedbackCxt: IFeedbackContext = {
    addMessage,
    showLoadingScreen,
  };
  return (
    <FeedbackContext.Provider value={feedbackCxt}>
      <div>
        {isLoading && <LoadingScreen />}
        {contextHolder}
        {children}
      </div>
    </FeedbackContext.Provider>
  );
};

export const useFeedback = (): IFeedbackContext => {
  const context = useContext(FeedbackContext) as IFeedbackContext;
  return context;
};
