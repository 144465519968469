import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useFeedback } from '../../contexts/feedback';
import { BackendlessSkuRecord } from '../../types';
import { skuApi } from '../../api';

interface SelectSKUsProps {
  placeholder?: string;
  style?: React.CSSProperties;
  onSelect(sku: BackendlessSkuRecord): void;
  sku?: BackendlessSkuRecord;
}

const SelectSKUs = ({
  placeholder, style, onSelect, sku,
}: SelectSKUsProps) => {
  const { addMessage } = useFeedback();

  const [data, setData] = useState<Array<BackendlessSkuRecord> | []>([]);
  const [value, setValue] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const searchFirst = async () => {
      const params = new URLSearchParams();
      params.append('pageSize', '20');
      params.set('loadRelations', 'lab,shippingQuality');
      const result = await skuApi.fetchSKUs(params);
      if (result) {
        setData(result);
      }
    };

    if (data.length === 0) {
      searchFirst();
    }
    if (sku) {
      setValue(sku.sku);
    }
  }, []);

  const onSearch = async (term: string) => {
    if (term.length > 2) {
      setLoading(true);
      // call api to search SKUs by term
      const params = new URLSearchParams();
      const where = `sku LIKE '%${term}%' OR lab_prefix LIKE '%${term}%'`;
      params.append('pageSize', '100');
      params.set('loadRelations', 'lab,shippingQuality');
      params.append('where', where);
      const result = await skuApi.fetchSKUs(params);
      if (result) {
        setLoading(false);
        setData(result);
      } else {
        setLoading(false);
        addMessage({ content: 'Sorry, the fetch return no results', severity: 'error'});
      }
    }
  };

  const onChange = (current: string) => {
    setValue(current);
  };

  const sendSelect = (objectId: string) => {
    const selectedSKU = data.find((d) => d.objectId === objectId);
    if (selectedSKU) {
      onSelect(selectedSKU);
    }
  };

  return (
    <Select
      loading={loading}
      showSearch={true}
      value={value}
      placeholder={placeholder}
      style={style}
      showArrow={true}
      filterOption={false}
      onSearch={onSearch}
      onChange={onChange}
      onSelect={(props, dataSelected) => sendSelect(dataSelected.value)}
      options={[...data.map((d) => ({
        value: d.objectId,
        label: `${d.sku} ${d.partner?.name || ''}, ${d.lab_prefix}, ${d.lab?.name || ''} - ${d.description}`,
      }))]}
    />
  );
};
export default SelectSKUs;
