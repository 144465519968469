import ApiClient, { IApiClient } from './ApiClient';
import {
  BackendlessCloudPrintKitIds, BackendlessKitRecord, BackendlessSkuRecord, IBackendlessCloudCreateKitIdsDTO, IBackendlessCloudPrintKitIdsDTO
} from '../types';

const urlSubPath = '/data/KitIDs';
const cloudFunctionsSubPath = '/services/KitIDs';

export default class KitApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }

  public fetchKitsCount = async (params: URLSearchParams): Promise<number> => {
    const data = await this.apiClient.get<number>(`${urlSubPath}/count`, { params });
    return data;
  };

  public fetchKits = async (params: URLSearchParams): Promise<BackendlessKitRecord[]> => {
    const data = await this.apiClient.get<BackendlessKitRecord[]>(`${urlSubPath}`, { params });
    return data;
  };

  public fetchKitsById = async (objectId: string): Promise<BackendlessKitRecord> => {
    const params = new URLSearchParams();
    const where = `objectId = '${objectId}'`;
    params.set('where', where);
    params.set('loadRelations', 'sku.lab');
    const data = await this.apiClient.get<BackendlessKitRecord[]>(`${urlSubPath}`, { params });
    return data[0];
  };

  public updateKit = async (kit: BackendlessKitRecord): Promise<BackendlessKitRecord> => {
    const data = await this.apiClient.put<BackendlessKitRecord, BackendlessKitRecord>(`${urlSubPath}/${kit.objectId}`, kit);
    return data;
  };

  public createKit = async (kit: BackendlessKitRecord): Promise<BackendlessKitRecord> => {
    const data = await this.apiClient.post<BackendlessKitRecord, BackendlessKitRecord>(`${urlSubPath}`, kit);
    return data;
  };

  public deleteKit = async (objectId: string): Promise<void> => {
    await this.apiClient.delete(`${urlSubPath}/${objectId}`);
  };

  public addSkuRelation = async (objectId: string, sku: BackendlessSkuRecord): Promise<BackendlessKitRecord> => {
    const data = await this.apiClient.post<string[], BackendlessKitRecord>(`${urlSubPath}/${objectId}/sku`, [sku.objectId]);
    return data;
  };

  // cloud functions

  public createKitIds = async (dto: IBackendlessCloudCreateKitIdsDTO): Promise<string[]> => {
    const data = await this.apiClient.post<IBackendlessCloudCreateKitIdsDTO, string[]>(`${cloudFunctionsSubPath}/createKitIds`, dto);
    return data;
  };

  public printKitIds = async (dto: IBackendlessCloudPrintKitIdsDTO): Promise<BackendlessCloudPrintKitIds> => {
    const data = await this.apiClient.post<IBackendlessCloudPrintKitIdsDTO, BackendlessCloudPrintKitIds>(`${cloudFunctionsSubPath}/printKitIds`, dto);
    return data;
  };
}
