import { authApi } from '../api';
import AppEventEmitter from '../utils/AppEventEmitter';
import { getItem, setItem, removeItem } from '../utils/LocalStorageUtil';

class AuthService extends AppEventEmitter {
  private TOKEN_REF = 'TOKEN';

  public init(): void {
    this.handleAuthentication();
  }

  private handleAuthentication(): void {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');
      return;
    }

    this.setSession(access_token);
    this.emit('onAutoLogin', true);
  }

  private getAccessToken(): string | null {
    return getItem(this.TOKEN_REF);
  }

  private setSession(access_token: string | null): void {
    if (access_token) {
      setItem(this.TOKEN_REF, access_token);
    } else {
      removeItem(this.TOKEN_REF);
    }
  }

  public async signIn(login: string, password: string, redirectUrl?: string): Promise<void> {
    const userToken = await authApi.login({
      login,
      password,
    });
    this.emit('onLogin', userToken, redirectUrl);
  }

  public logout(): void {
    this.setSession(null);
    this.emit('onLogout');
  }
}

const instance = new AuthService();

export default instance;
