import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import authService from '../services/AuthService';
import { getItem } from '../utils/LocalStorageUtil';

type HttpHeaders = {
  [key: string]: string;
};

type RequestConfig = {
  headers?: HttpHeaders;
  timeout?: number;
  params?: URLSearchParams;
};

export interface IApiClient {
  post<TRequest, TResponse>(path: string, object: TRequest, config?: RequestConfig): Promise<TResponse>;
  patch<TRequest, TResponse>(path: string, object: TRequest, config?: RequestConfig): Promise<TResponse>;
  put<TRequest, TResponse>(path: string, object: TRequest, config?: RequestConfig): Promise<TResponse>;
  get<TResponse>(path: string, config?: RequestConfig): Promise<TResponse>;
  delete<TResponse>(path: string, config?: RequestConfig): Promise<TResponse>
  getWithBodyFilter<TRequest, TResponse>(path: string, object: TRequest, config?: RequestConfig): Promise<TResponse>;
}

export interface IApiClientError {
  message: string;
  name: string | undefined;
  stack: string | undefined;
}

export default class ApiClient implements IApiClient {
  private client: AxiosInstance;

  protected createAxiosClient(baseURL: string): AxiosInstance {
    const token = getItem('TOKEN');
    return Axios.create({
      baseURL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'user-token': token,
      },
      timeout: 10 * 1000,
    });
  }

  constructor(baseURL: string) {
    this.client = this.createAxiosClient(baseURL);
    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.client.interceptors.response.use(this._handleResponse, this._handleError);
  };

  private _handleResponse = (response: AxiosResponse) => response;

  protected _handleError = (error: AxiosError): Promise<IApiClientError> => {
    const errorObj = {
      message: error.message,
      name: error.code,
      stack: error.stack,
      response: error.response,
    };

    if (error.response?.status === 401 || error.response?.status === 403) {
      authService.logout();
    }

    return Promise.reject(errorObj);
  };

  private getClient = (): AxiosInstance => {
    const token = getItem('TOKEN');
    this.client.defaults.headers['user-token'] = token;
    return this.client;
  };

  async post<TRequest, TResponse>(path: string, payload: TRequest, config?: RequestConfig): Promise<TResponse> {
    const response = config ? await this.getClient().post<TResponse>(path, payload, config) : await this.getClient().post<TResponse>(path, payload);
    return response.data;
  }

  async patch<TRequest, TResponse>(path: string, payload: TRequest, config?: RequestConfig): Promise<TResponse> {
    const response = config ? await this.getClient().patch<TResponse>(path, payload, config) : await this.getClient().patch<TResponse>(path, payload);
    return response.data;
  }

  async put<TRequest, TResponse>(path: string, payload: TRequest, config?: RequestConfig): Promise<TResponse> {
    const response = config ? await this.getClient().put<TResponse>(path, payload, config) : await this.getClient().put<TResponse>(path, payload);
    return response.data;
  }

  async get<TResponse>(path: string, config?: RequestConfig): Promise<TResponse> {
    const response = await this.getClient().get<TResponse>(path, config);
    return response.data;
  }

  async getWithBodyFilter<TRequest, TResponse>(path: string, payload: TRequest): Promise<TResponse> {
    const response = await this.getClient().get<TResponse>(path, {
      data: payload,
    });
    return response.data;
  }

  async delete<TResponse>(path: string, config?: RequestConfig): Promise<TResponse> {
    const response = config ? await this.getClient().delete<TResponse>(path, config) : await this.getClient().delete<TResponse>(path);
    return response.data;
  }
}
