import React, { useState } from 'react';
import { Button, Result } from 'antd';
import PrintLabelsForm from './components/PrintLabelsForm';
import SelectSKUs from '../../components/SelectSKUs';
import AuthTemplate from '../../layouts/auth';
import {
  BackendlessKitRecord,
  BackendlessQualityControlRecord, BackendlessSkuRecord, IBulkOrder, IPrintLabels
} from '../../types';
import PrintLabelsSteps from './components/PrintLabelsSteps';
import PrintLabelNumberForm from './components/PrintLabelNumberForm';
import {
  kitApi, shippingAPi, qualityControlApi, trackingApi
} from '../../api';
import LoadingSteps from '../../components/LoadingSteps';
import { QualityControlStatus } from '../../utils/QualityControlStatusEnum';

const BuildBulkOrder = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [sku, setSKU] = useState<BackendlessSkuRecord | undefined>();
  const [printOptions, setPrintOptions] = useState<IPrintLabels | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPercentage, setLoadingPercentage] = useState<number>(10);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();

  const onSelectSKU = async (selectedSKU: BackendlessSkuRecord) => {
    // fill info
    setSKU(selectedSKU);
    // change step
    setCurrentStep(1);
    // clear pre selected sku data
    setPrintOptions(undefined);
  };

  const onNextStep = (values: IPrintLabels) => {
    const {
      printerLabelId, lab, printerKitId, quality,
    } = values;

    const options = {
      printerLabelId,
      lab,
      printerKitId,
      quality,
      numberOfLabels: 0,
      isPrintingKitLabel: false,
      isPrintingShippingLabel: false,
    } as IPrintLabels;

    setPrintOptions(options);
    setCurrentStep(2);
  };

  function getRandomSubset(list: BackendlessKitRecord[]): BackendlessKitRecord[] {
    const shuffledList = [...list]; // Create a copy of the original list
    let currentIndex = shuffledList.length;

    // While there are elements to shuffle
    while (currentIndex > 0) {
      // Pick a remaining element
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // Swap it with the current element
      const temp = shuffledList[currentIndex];
      shuffledList[currentIndex] = shuffledList[randomIndex];
      shuffledList[randomIndex] = temp;
    }

    // Pick the first 10% of the shuffled list
    const subsetSize = Math.ceil(shuffledList.length * 0.1);
    const subset = shuffledList.slice(0, subsetSize);

    return subset;
  }

  const createQC = async (kitIds: BackendlessKitRecord[]) => {
    // find 10% to qc
    const kitsToQC = getRandomSubset(kitIds);
    // generate qc records

    const qcRecords = kitsToQC.map(() => ({
      status: QualityControlStatus.INCOMPLETE,
    }));

    // save qc records
    const promisesCreating = qcRecords.map((q) => qualityControlApi.createQualityControl(q as BackendlessQualityControlRecord));
    const qc = await Promise.all(promisesCreating);

    // set relation
    const promisesRelation = qc.map((q, index) => qualityControlApi.addKitOnQualityControlRecord(q.objectId, kitsToQC[index]));
    await Promise.all(promisesRelation);
  };

  const onConfirm = async (values: IBulkOrder) => {
    try {
      setLoading(true);
      const {
        numberOfLabels, trackingNumber, shopifyNumber, carrier,
      } = values;

      const options = {
        ...printOptions,
        ...values,
        numberOfLabels,
      } as IBulkOrder;

      setPrintOptions(options);
      setCurrentStep(3);
      if (sku) {
        let maxSteps = 3;
        if (trackingNumber) maxSteps -= 1;
        setLoadingPercentage(100 - (30 * maxSteps));
        setLoadingMessage('Creating new kits...');
        const kitIds = await kitApi.createKitIds({ number: options.numberOfLabels, sku: sku.sku });

        // call printing for kits
        maxSteps -= 1;
        setLoadingPercentage(100 - (30 * maxSteps));
        setLoadingMessage('Printing kits...');
        await kitApi.printKitIds({kitObjectIds: kitIds, printerId: options.printerKitId});

        // call printing for kits
        if (!trackingNumber) {
          maxSteps -= 1;
          setLoadingPercentage(100 - (30 * maxSteps));
          setLoadingMessage('Printing the shipping labels...');
          await shippingAPi.createShippingLabel({
            lab: options.lab,
            printerId: options.printerLabelId,
            saturdayDelivery: false,
            shippingQuality: options.quality,
            number: options.numberOfLabels,
            kits: kitIds,
          });
        }

        // set tracking number in all objects and the shopify number
        await Promise.all(kitIds.map((id) => {
          if (trackingNumber && carrier) {
            return kitApi.updateKit({
              objectId: id, tracking: trackingNumber, shopify_order_number: shopifyNumber, carrier,
            } as BackendlessKitRecord);
          } else {
            return kitApi.updateKit({ objectId: id, shopify_order_number: shopifyNumber } as BackendlessKitRecord);
          }
        }));

        const params = new URLSearchParams();
        const where = `objectId IN ( ${kitIds.map((id) => `'${id}'`)} )`;
        params.set('where', where);
        params.set('pageSize', '100');
        const kits = await kitApi.fetchKits(params);

        await createQC(kits);

        // sync tracking data
        const promisesDataSync = kits.filter((k) => k.shopify_order_number && k.tracking).map((kitId) => trackingApi.dataSync({
          shopify_order_id: kitId.shopify_order_number, kit_id: kitId.kitid, return_tracking_number: kitId.tracking, carrier: kitId.carrier,
        }));

        await Promise.all(promisesDataSync);

        setLoading(false);
        setSKU(undefined);
        setLoadingMessage('');
        setLoadingPercentage(100);
      }
    } catch (e: unknown) {
      setLoading(false);
      setLoadingMessage('');
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  const onStartAgain = () => {
    setCurrentStep(0);
    setSKU(undefined);
    setPrintOptions(undefined);
  };

  return (
    <AuthTemplate active='/print'>
      <h1>Build Bulk Order</h1>
      <PrintLabelsSteps sku={sku} step={currentStep} onChange={(step: number) => setCurrentStep(step)}>
        {
          currentStep === 0 && <div>
            <h2>Select SKU</h2>
            <SelectSKUs sku={sku} onSelect={onSelectSKU} placeholder={'Search by SKU.'} style={{width: '90%'}}/>
          </div>
        }
        {
          currentStep === 1 && <div>
            <h2>Confirm the Lab</h2>
            <div style={{ width: '100%'}}>
              <PrintLabelsForm sku={sku} onConfirm={onNextStep} />
            </div>
          </div>
        }
        {
          currentStep === 2 && <div>
            <h2>Printing</h2>
            <div style={{ width: '100%', margin: 'auto', marginTop: 20}}>
              <PrintLabelNumberForm onConfirm={onConfirm} />
            </div>
          </div>
        }
        {
          loading && <LoadingSteps percentage={loadingPercentage} message={loadingMessage}/>
        }
        {
          !loading && currentStep === 3 && (!sku || !!error) && <Result
            status={error ? 'error' : 'success'}
            title={error || 'Successfully created the kits!'}
            extra={[
              <Button key={'download'} type='primary' onClick={onStartAgain}> Build New Bulk </Button>,
            ]}

          />
        }
      </PrintLabelsSteps>
    </AuthTemplate>
  );
};

export default BuildBulkOrder;
