import ApiClient, { IApiClient } from './ApiClient';

const urlSubPath = '/users';

interface LoginRequest {
  login: string;
  password: string;
}

interface LoginResponse {
  'user-token': string;
}

export default class AuthApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }

  public login = async (payload: LoginRequest): Promise<string> => {
    const data = await this.apiClient.post<LoginRequest, LoginResponse>(`${urlSubPath}/login`, payload);
    return data['user-token'];
  };
}
