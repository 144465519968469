import AuthApi from './AuthApi';
import CarrierApi from './CarrierApi';
import KitApi from './KitApi';
import LabApi from './LabApi';
import PrinterApi from './PrinterApi';
import ShippingApi from './ShippingApi';
import ShippingQualityApi from './ShippingQualityApi';
import SkuApi from './SkuApi';
import TrackingApi from './TrackingApi';
import QualityControlApi from './QualityControlApi';
import UserApi from './UserApi';
import OrderApi from './OrderApi';

export const skuApi = new SkuApi();
export const labApi = new LabApi();
export const printerApi = new PrinterApi();
export const carrierApi = new CarrierApi();
export const shippingQualityApi = new ShippingQualityApi();
export const kitApi = new KitApi();
export const shippingAPi = new ShippingApi();
export const authApi = new AuthApi();
export const trackingApi = new TrackingApi();
export const qualityControlApi = new QualityControlApi();
export const userApi = new UserApi();
export const orderApi = new OrderApi();
