/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Button, Modal, Table, Tooltip
} from 'antd';
import { EditFilled } from '@ant-design/icons';
import EditableCell from '../../components/EditableCell';
import AuthTemplate from '../../layouts/auth';
import { BackendlessSkuRecord } from '../../types';
import { useFeedback } from '../../contexts/feedback';
import DashboardHeader from './components/header';
import { skuApi } from '../../api';
import SkuForm from '../../components/SkuForm';

const newSKU = {
  objectId: '',
  lab_prefix: '',
  description: '',
  sku: '',
};

const SkuPage = () => {
  const { addMessage } = useFeedback();

  const [data, setData] = useState<Array<BackendlessSkuRecord> | []>([]);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [skuModal, setSkuModal] = useState<{ open: boolean; sku: Partial<BackendlessSkuRecord> }>({
    open: false,
    sku: newSKU,
  });
  // eslint-disable-next-line max-len
  const whereQuery = (searchTerm: string) => `description LIKE '%${searchTerm}%' OR lab_prefix LIKE '%${searchTerm}%' OR sku LIKE '%${searchTerm}%'`;

  const onFetchData = async (params: URLSearchParams) => {
    const itemsTotalCount = await skuApi.fetchSKUsCount(params);
    const items = await skuApi.fetchSKUs(params);

    if (items) {
      setData([...items]);
      setTotal(itemsTotalCount);
    }
  };

  useEffect(() => {
    if (data?.length === 0) {
      const params = new URLSearchParams();
      params.append('pageSize', '10');
      params.append('offset', '0');
      onFetchData(params);
    }
  }, []);

  const onEdit = (record: BackendlessSkuRecord) => {
    setSkuModal({
      open: true,
      sku: record,
    });
  };

  const onSearch = async (searchTerm: string) => {
    const params = new URLSearchParams();
    params.append('pageSize', '10');
    params.append('offset', '0');
    params.append('where', whereQuery(searchTerm).toString());
    onFetchData(params);
    setSearch(searchTerm);
  };

  const onClearSearch = () => {
    const params = new URLSearchParams();
    params.append('pageSize', '10');
    params.append('offset', '0');
    onFetchData(params);
  };

  const onAdd = () => {
    setSkuModal({
      open: true,
      sku: newSKU,
    });
  };

  const onCancel = () => {
    setSkuModal({
      open: false,
      sku: newSKU,
    });
  };

  const onChangePagination = (page: number, pagesize: number) => {
    const offset = (page - 1) * pagesize;
    const params = new URLSearchParams();
    params.append('pageSize', pagesize.toString());
    params.append('offset', offset.toString());

    if (search) {
      params.append('where', whereQuery(search).toString());
    }

    onFetchData(params);
  };

  const onSave = async (sku: BackendlessSkuRecord) => {
    try {
      if (!sku.objectId) {
        // create
        const res = await skuApi.createSKU(sku);
        setData([res, ...data]);
        addMessage({
          severity: 'success',
          content: 'SKU was created successfully',
        });
      } else {
        await skuApi.updateSKU(sku);
        const index = data.findIndex(
          (record) => record.objectId === sku.objectId
        );
        data[index] = {
          ...data[index],
          ...sku,
        };
        setData([...data]);
        addMessage({
          severity: 'success',
          content: 'SKU was updated successfully',
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response && e.response.data) {
        addMessage({
          severity: 'error',
          content: `Error updating SKU - ${e.response.data.code} ${e.response.data.message}`,
        });
      }
    }
    setSkuModal({
      open: false,
      sku: newSKU,
    });
  };

  const columns = [
    {
      title: 'Lab Prefix',
      dataIndex: 'lab_prefix',
      width: '15%',
      editable: true,
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: '15%',
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '50%',
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_: any, record: BackendlessSkuRecord) => (
        <span data-testid="non-selected-action">
          <Tooltip title="Edit SKU">
            <Button data-testid="edit-action" style={{ marginRight: 10 }} onClick={() => onEdit(record)} type="primary" shape="circle" icon={<EditFilled />} />
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <AuthTemplate active="/">
      <h1>SKUs</h1>
      <DashboardHeader onAdd={onAdd} onSearch={onSearch} onClearSearch={onClearSearch} />
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={columns}
        pagination={{
          total,
          onChange: onChangePagination,
          showSizeChanger: false,
        }}
      />
      <Modal destroyOnClose title={skuModal.sku.objectId ? 'Edit SKU' : 'Create SKU'} footer={null} open={skuModal.open} onCancel={onCancel}>
        <SkuForm sku={skuModal.sku as BackendlessSkuRecord} onCancel={onCancel} onSave={onSave} />
      </Modal>
    </AuthTemplate>
  );
};

export default SkuPage;
