const alphabet = [
  { char: '0', list: ['0', 'O', 'Q'] },
  { char: '1', list: ['1', 'L', 'I'] },
  { char: '5', list: ['5', 'S'] },
  { char: '8', list: ['8', 'B'] },
  { char: 'V', list: ['V', 'U'] },
];

/**
 * This will clear the activation codes safe in case it's a kitId
 * It will reduce the noise of any typo
 * @param activationId
 * @returns normalized string or the current kitid
 */
const activationIdNormalization = (activationId: string): string => {
  // that means it's a kit ID.
  // activationId must to have 7 alphanumerics
  if (!activationId || activationId.length > 7) return activationId;
  const listChars = activationId.split('');
  const normalized = listChars.map((char) => {
    const charNormalized = alphabet.find((alphabetItem) => alphabetItem.list.find((nonNormalized) => char.toUpperCase() === nonNormalized));
    if (charNormalized) {
      return charNormalized.char;
    }
    return char.toUpperCase();
  });
  return normalized.join('');
};

export const useNormalization = () => ({
  activationIdNormalization,
});
