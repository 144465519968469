import React from 'react';
import { Button, Col, Row } from 'antd';
import SearchBar from '../../../components/SearchBar';

interface DashboardHeaderProps {
  onAdd(): void;
  onSearch(searchTerm: string): void;
  onClearSearch(): void;
}

const DashboardHeader = ({ onAdd, onSearch, onClearSearch }: DashboardHeaderProps) => (
  <Row>
    <Col data-testid="search-bar" span={17}>
      <SearchBar
        placeholder="Search SKUs"
        isLoading={false}
        size="large"
        onSearch={onSearch}
        onClearSearch={onClearSearch}
      />
    </Col>
    <Col offset={1} span={6}>
      <Button
        data-testid="add-button"
        shape="round"
        style={{ marginBottom: 10 }}
        size="large"
        type="primary"
        block
        onClick={onAdd}
      >
          Add New
      </Button>
    </Col>
  </Row>
);

export default DashboardHeader;
