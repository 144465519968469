import React, { useRef, useState } from 'react';
import {
  Button, Col, Form, Input, InputRef, Row
} from 'antd';
import AuthTemplate from '../../layouts/auth';
import { kitApi, trackingApi } from '../../api';
import {
  BackendlessKitRecord
} from '../../types';
import { useFeedback } from '../../contexts/feedback';

const ENTER = 13;

const KitShopify = () => {
  const [form] = Form.useForm();
  const inputRefs = useRef<Array<React.RefObject<InputRef>>>([]);

  const { addMessage } = useFeedback();

  const [kitError, setKitError] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [kitIds, setKitIds] = useState<BackendlessKitRecord[]>([]);
  const [numberOfKits, setNumberOfKits] = useState<number>(1);

  const insertNewError = (index: number, newValue: string) => {
    const errors = [...kitError];
    errors[index] = newValue;
    setKitError(errors);

    if (kitIds.length > 0 && kitIds[index]) {
      const k = [...kitIds];
      k.splice(index, 1);
      setKitIds([...k]);
    }
  };

  const hasErrors = () => !!kitError.find((error) => !!error);

  const getKitIds = async (kits: string[]): Promise<BackendlessKitRecord[]> => {
    const params = new URLSearchParams();
    const where = `kitid IN (${kits.map((k) => `'${k}'`)})`;
    params.set('where', where);
    params.set('loadRelations', 'sku.lab');

    const data = await kitApi.fetchKits(params);
    if (data && data.length > 0) {
      data.forEach((foundKit: BackendlessKitRecord, index: number) => {
        if (foundKit.shopify_order_number) {
          insertNewError(index, 'This Kit Id is already associated to another shopify order.');
        }
      });
      return data;
    }
    return [];
  };

  const toFindDuplicates = (arry: string[]) => arry.filter((item, index) => arry.indexOf(item) !== index);

  const onSubmit = async () => {
    setLoading(true);
    setKitError(Array(numberOfKits).fill(undefined).map(() => ''));
    try {
      const formFields = form.getFieldsValue();

      // fetch all kits
      delete formFields.shopifyOrder;
      const kits = Object.values(formFields) as string[];
      let kitObjects: BackendlessKitRecord[] = [];

      if (kits && kits.length > 0) {
        // check duplicates
        const duplicates = toFindDuplicates(kits);
        if (duplicates && duplicates.length > 0) {
          duplicates.forEach((d) => insertNewError(kits.indexOf(d), 'This Kit Id is already scanned.'));
          return;
        }
        kitObjects = await getKitIds(kits);
        if (kitObjects.length !== kits.length) {
          // find some of them it was not found
          const notFound = kits.find((k) => !kitObjects.find((_k) => _k.kitid === k));
          if (notFound) {
            insertNewError(kits.indexOf(notFound), 'This Kit was not found.');
            return;
          }
        }

        if (!hasErrors() && kitObjects) {
          const promises = kitObjects.map((kit: BackendlessKitRecord) => {
            const kitId = kit;
            kitId.shopify_order_number = form.getFieldValue('shopifyOrder');
            return kitApi.updateKit(kitId);
          });

          await Promise.all(promises);

          form.resetFields();
          setKitIds([]);
          addMessage({content: 'Shopify order fulfill successfully', severity: 'success'});

          // sync tracking data
          const promisesDataSync = kitIds.filter((k) => k.shopify_order_number && k.tracking).map((kitId) => trackingApi.dataSync({
            shopify_order_id: kitId.shopify_order_number, kit_id: kitId.kitid, return_tracking_number: kitId.tracking, carrier: kitId.carrier,
          }));

          await Promise.all(promisesDataSync);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        addMessage({ content: e.message, severity: 'error'});
      }
    } finally {
      setLoading(false);
    }
  };

  const onKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (index < numberOfKits - 1) {
      inputRefs.current[index + 1].current?.focus();
    }
    event.preventDefault();
  };

  const addKitIdInput = () => {
    const screenList = Array.from({ length: numberOfKits }, (_, i) => i);
    inputRefs.current = Array(numberOfKits).fill(null).map((_, i) => inputRefs.current[i] || React.createRef());
    return screenList.map((index: number) => (
      <Form.Item
        key={index}
        label="Kit Id / Activation Id" name={`kitid${index}`} rules={[{ type: 'string', min: 7 }]}
        help={kitError[index] ? kitError[index] : null}
        validateStatus={kitError[index] ? 'error' : 'validating'}>
        <Input
          ref={inputRefs.current[index]}
          onChange={() => setKitError(Array(numberOfKits).fill(undefined).map(() => ''))}
          data-testid={`kitid${index}`} name={`kitid${index}`} onKeyDown={(e) => (e.keyCode === ENTER ? onKeyDown(index, e) : '')}/>
      </Form.Item>
    ));
  };

  const onChangeNumberKits = (e: React.FormEvent<HTMLInputElement>) => {
    const value = Number(e.currentTarget.value);
    setKitError(Array(value).fill(undefined).map(() => ''));
    setKitIds([]);
    setNumberOfKits(value);
  };

  return (
    <AuthTemplate active='/shopify'>
      <h1>Manage Shopify Order</h1>
      <Row gutter={20}>
        <Col flex={1}>
          <Form form={form} layout='vertical' onFinish={onSubmit}>
            <Form.Item rules={[
              {
                required: true,
                message: 'Please, add a Shopify order number!',
              },
            ]}
            label="Shopify Order" name="shopifyOrder">
              <Input data-testid="shopifyOrder" name='shopifyOrder' />
            </Form.Item>

            <Form.Item required label="Number of Kits">
              <Input value={numberOfKits} type='number' data-testid="numberOfKits" onChange={onChangeNumberKits} />
            </Form.Item>

            { addKitIdInput() }

            <Form.Item>
              <Button data-testid="submit-btn"
                loading={loading}
                htmlType="submit"
                style={{marginTop: 30}}
                block
                type="primary">
                Submit
              </Button>
            </Form.Item>

          </Form>
        </Col>
      </Row>
    </AuthTemplate>
  );
};

export default KitShopify;
