import React from 'react';
import { Form, Input } from 'antd';
import { EditableCellProps } from '../../types';

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  children,
  ...restProps
}) => (
  <td {...restProps}>
    {editing ? (
      <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message: `Please Input ${title}!`,
          },
        ]}
      >
        <Input data-testid={`${dataIndex}-test`} />
      </Form.Item>
    ) : (
      children
    )}
  </td>
);

export default EditableCell;
