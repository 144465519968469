import ApiClient, { IApiClient } from './ApiClient';
import { BackendlessPrinterRecord } from '../types';

const urlSubPath = '/data/Printers';

export default class PrinterApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }

  public fetchPrintersCount = async (params: URLSearchParams): Promise<number> => {
    const data = await this.apiClient.get<number>(`${urlSubPath}/count`, { params });
    return data;
  };

  public fetchPrinters = async (params?: URLSearchParams): Promise<BackendlessPrinterRecord[]> => {
    const data = await this.apiClient.get<BackendlessPrinterRecord[]>(`${urlSubPath}`, { params });
    return data;
  };

  public updatePrinter = async (kit: BackendlessPrinterRecord): Promise<BackendlessPrinterRecord> => {
    const data = await this.apiClient.put<BackendlessPrinterRecord, BackendlessPrinterRecord>(`${urlSubPath}/${kit.objectId}`, kit);
    return data;
  };

  public createPrinter = async (printer: BackendlessPrinterRecord): Promise<BackendlessPrinterRecord> => {
    const data = await this.apiClient.post<BackendlessPrinterRecord, BackendlessPrinterRecord>(`${urlSubPath}`, printer);
    return data;
  };

  public deletePrinter = async (objectId: string): Promise<void> => {
    await this.apiClient.delete(`${urlSubPath}/${objectId}`);
  };
}
