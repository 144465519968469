import React from 'react';
import {
  Button, Form, Input, Select, Tabs
} from 'antd';
import { IBulkOrder } from '../../../../types';

interface PrintLabelNumberFormProps {
  onConfirm(params: IBulkOrder): void;
}

const PrintLabelNumberForm = ({ onConfirm }: PrintLabelNumberFormProps) => {
  const [form] = Form.useForm();

  return (
    <Tabs>
      <Tabs.TabPane tab="One Return Tracking Label For Entire Order" key="tab-1" data-testid="tab1">
        <Form form={form} layout='vertical' onFinish={onConfirm}>
          <Form.Item label='Number of Kits' name='numberOfLabels' rules={[
            {
              required: true,
              message: 'Number of labels is required.',
            },
            {
              message: 'Number of kits cannot be more than 100',
              validator: (_, value) => {
                if (value <= 100) {
                  return Promise.resolve();
                }
                throw new Error('Number of kits cannot be more than 100');
              },
            },
          ]}>
            <Input type='number'/>
          </Form.Item>
          <Form.Item label='Shopify Order Id' name='shopifyNumber' required>
            <Input type='text'/>
          </Form.Item>
          <Form.Item label='Tracking Number' name='trackingNumber' required>
            <Input type='text'/>
          </Form.Item>
          <Form.Item label='Carrier' name='carrier' required>
            <Select>
              <Select.Option value="USPS">USPS</Select.Option>
              <Select.Option value="UPS">UPS</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button data-testid='submit-btn'
              htmlType='submit'
              style={{marginTop: 30}}
              size='large'
              type='primary'>
                        Print Labels
            </Button>
          </Form.Item>
        </Form>
      </Tabs.TabPane>
      <Tabs.TabPane tab="One Return Tracking Label For Each Kit ID" key="tab-2" data-testid="tab2">
        <Form form={form} layout='vertical' onFinish={onConfirm}>
          <Form.Item label='Number of Kits' name='numberOfLabels' rules={[
            {
              required: true,
              message: 'Number of labels is required.',
            },
            {
              message: 'Number of kits cannot be more than 100',
              validator: (_, value) => {
                if (value <= 100) {
                  return Promise.resolve();
                }
                throw new Error('Number of kits cannot be more than 100');
              },
            },
          ]}>
            <Input type='number'/>
          </Form.Item>
          <Form.Item label='Shopify Order Id' name='shopifyNumber' required>
            <Input type='text'/>
          </Form.Item>
          <Form.Item>
            <Button data-testid='submit-btn'
              htmlType='submit'
              style={{marginTop: 30}}
              size='large'
              type='primary'>
                        Print Labels
            </Button>
          </Form.Item>
        </Form>
      </Tabs.TabPane>
    </Tabs>
  );
};

export default PrintLabelNumberForm;
