import React, { useEffect, useState } from 'react';
import { Button, Form, Select } from 'antd';
import {
  BackendlessLabRecord, BackendlessPrinterRecord, BackendlessShippingQualityRecord, BackendlessSkuRecord, IPrintLabels
} from '../../../../types';
import {
  labApi,
  printerApi,
  shippingQualityApi
} from '../../../../api';

interface PrintLabelsFormProps {
  onConfirm(params: IPrintLabels): void;
  sku?: BackendlessSkuRecord;
}

const PrintLabelsForm = ({sku, onConfirm}: PrintLabelsFormProps) => {
  const [form] = Form.useForm();

  const [printersLabel, setPrintersLabel] = useState<Array<BackendlessPrinterRecord> | undefined>([]);
  const [printersKit, setPrintersKit] = useState<Array<BackendlessPrinterRecord> | undefined>();
  const [labs, setLabs] = useState<Array<BackendlessLabRecord> | undefined>();
  const [shippingQualities, setShippingQualities] = useState<Array<BackendlessShippingQualityRecord> | undefined>();

  const fetchPrinters = async () => {
    if (sku) {
      const data = await printerApi.fetchPrinters();
      setPrintersLabel(data);
      setPrintersKit(data);
      form.setFieldsValue({
        printerKitId: data?.find((p) => p.name === 'KitID Printer (Austin BU)')?.printer_id,
        printerLabelId: data?.find((p) => p.name === 'Shipping Printer (Austin)')?.printer_id,
      });
    }
  };
  const fetchLab = async () => {
    if (sku) {
      const data = await labApi.fetchLabs();
      setLabs(data);
      form.setFieldValue('lab', sku.lab.objectId);
    }
  };

  const fetchShippingSpeed = async () => {
    if (sku) {
      const data = await shippingQualityApi.fetchShippingQualitys();
      setShippingQualities(data);
      form.setFieldValue('quality', sku.shippingQuality?.quality);
    }
  };
  useEffect(() => {
    if (sku) {
      Promise.all([fetchPrinters(), fetchLab(), fetchShippingSpeed()]);
    }
  }, [sku]);

  return (
    <Form form={form} layout='vertical' onFinish={onConfirm}>
      <Form.Item
        required
        label="Shipping Label Printer" name="printerLabelId">
        <Select options={printersLabel && [...printersLabel.map((d) => ({
          value: d.printer_id,
          label: d.name,
        }))]}/>
      </Form.Item>

      <Form.Item
        required
        label="Lab" name="lab">
        <Select defaultValue="" options={labs && [...labs.map((d) => ({
          value: d.objectId,
          label: d.name,
        }))]}/>
      </Form.Item>

      <Form.Item
        required
        label="Shipping Speed" name="quality">
        <Select options={shippingQualities && [...shippingQualities.map((d) => ({
          value: d.quality,
          label: d.name,
        }))]}/>
      </Form.Item>

      <Form.Item
        required
        label="Kit Label Printer" name="printerKitId">
        <Select options={printersKit && [...printersKit.map((d) => ({
          value: d.printer_id,
          label: d.name,
        }))]}/>
      </Form.Item>

      <Form.Item>
        <Button data-testid="submit-btn"
          size="large"
          htmlType="submit"
          style={{marginTop: 30}}
          type="primary">
          Next
        </Button>
      </Form.Item>

    </Form>
  );
};
export default PrintLabelsForm;
