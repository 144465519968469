/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import {
  Alert,
  Button, Col, Form, Input, Row, Space
} from 'antd';
import {BackendlessKitRecord} from '../../types';
import { useFeedback } from '../../contexts/feedback';
import { kitApi } from '../../api';
import AuthTemplate from '../../layouts/auth';
import Style from './styles';
import './ant.css';

interface iKitTracking {
  kitId?: BackendlessKitRecord,
  tracking?: string;
}
const ENTER = 13;
const KitTracking = () => {
  const [form] = Form.useForm();
  const { addMessage } = useFeedback();

  const [kitError, setKitError] = useState<string | null>(null);
  const [trackError, setTrackError] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [kitTrackingList, setKitTrackingList] = useState<iKitTracking[]>([]);
  const [numberQC, setNumberQC] = useState<number | null>(null);

  const getKitByKitId = async (index: number) => {
    const hasKitWithSameId = kitTrackingList.filter((kitTrack: iKitTracking) => kitTrack.kitId?.kitid === form.getFieldValue(`kitid${index}`));
    if (hasKitWithSameId && hasKitWithSameId.length > 0) {
      setKitError('KitId already added');
      return;
    }
    const params = new URLSearchParams();
    const where = `kitid LIKE '${form.getFieldValue(`kitid${index}`)}' OR activationid LIKE '${form.getFieldValue(`kitid${index}`)}'`;
    params.set('where', where);
    params.set('loadRelations', 'sku.lab');

    const data = await kitApi.fetchKits(params);
    if (data && data.length > 0) {
      const kit = data[0];
      if (kit.tracking) {
        setKitError('This Kit Id is already associated to another tracking number.');
        return;
      } else if (!kit.sku || !kit.sku.lab) {
        setKitError('This Kit Id has no Lab associated.');
        return;
      }
      setKitError(null);
      setKitTrackingList((prev: iKitTracking[]) => [...prev, { kitId: kit }]);
    } else {
      setKitError('Kit Id not found.');
    }
  };

  const addTracking = async (index: number) => {
    const hasKitWithSameTracking = kitTrackingList.filter((kitTrack: iKitTracking) => kitTrack.tracking === form.getFieldValue(`tracking${index}`));
    const copy = [];
    if (hasKitWithSameTracking && hasKitWithSameTracking.length > 0) {
      copy[index] = 'Tracking code already added';
      setTrackError(copy);
      return;
    }
    const params = new URLSearchParams();
    const where = `tracking LIKE '${form.getFieldValue(`tracking${index}`)}'`;
    params.set('where', where);
    const data = await kitApi.fetchKits(params);
    if (data && data.length > 0) {
      const kit = data[0];
      if (kit.tracking) {
        copy[index] = 'This Tracking Number is associated to another kit with a different kit ID.';
        setTrackError(copy);
        return;
      }
    }
    setTrackError([]);

    const trackingCopy = [...kitTrackingList];
    const kitTracking = trackingCopy[index];
    kitTracking.tracking = form.getFieldValue(`tracking${index}`);

    setKitTrackingList([...trackingCopy]);
  };

  const hasTrackingErrors = () => !!trackError.find((error) => !!error);

  const findKitWithoutTrackingNumber = () => kitTrackingList.find((kit) => !kit.tracking);

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (kitTrackingList.length > 0) {
        if (!!kitError || hasTrackingErrors()) {
          addMessage({content: 'There is some error. Please, fix it before submiting.', severity: 'error'});
          return;
        }

        if (findKitWithoutTrackingNumber()) {
          addMessage({content: 'Some of Kits are missing a return tracking number', severity: 'error'});
          return;
        }

        // calculate QC
        setNumberQC(Math.ceil(kitTrackingList.length / 10));

        // save all
        const promises = kitTrackingList.map((k: iKitTracking) => kitApi.updateKit({...k.kitId, tracking: k.tracking } as BackendlessKitRecord));
        await Promise.all(promises);
        form.resetFields();
        addMessage({content: 'Return Tracking added successfully', severity: 'success'});
        return;
      }

      addMessage({content: 'This should have at least 1 kitId and 1 return tracking number.', severity: 'error'});
      return;
    } catch (e) {
      if (e instanceof Error) {
        addMessage({ content: e.message, severity: 'error'});
      }
    } finally {
      setLoading(false);
    }
  };

  const rows = () => {
    const screenList = [...kitTrackingList, {} as iKitTracking];
    return screenList.map((kitTracking: iKitTracking, index: number) => (
      <Style.Row key={index} data-testid='row'>
        <Style.RowItem
          name={`kitid${index}`}
          help={(index === (screenList.length - 1)) ? kitError : null}
          validateStatus={(kitError && (index === (screenList.length - 1))) ? 'error' : 'validating'}>
          <Input data-testid={`kitid${index}`} name={`kitid${index}`} onChange={() => getKitByKitId(index)} onKeyDown={(e) => (e.keyCode === ENTER ? e.preventDefault() : '')}/>
        </Style.RowItem>

        <Style.RowDivider />

        <Style.RowItem
          name={`tracking${index}`}
          help={trackError[index]}
          validateStatus={trackError[index] ? 'error' : 'validating'}>
          <Input data-testid={`tracking${index}`} name={`tracking${index}`} onChange={() => addTracking(index)} onKeyDown={(e) => (e.keyCode === ENTER ? e.preventDefault() : '')}/>
        </Style.RowItem>
      </Style.Row>
    ));
  };

  return (
    <AuthTemplate active='/tracking'>
      <h1>Manage Tracking</h1>
      <Row gutter={20}>
        <Col flex={1}>
          {
            (numberQC && numberQC > 0) && <Space direction="vertical" style={{ width: '100%', padding: '10px' }}>
              <Alert message={`Kit #${numberQC}th - QC this item`} type="warning" />
            </Space>
          }
          <Form form={form} layout='vertical' onFinish={onSubmit}>
            <strong style={{marginLeft: 20}}>Kit Id/ Activation Id</strong>
            <strong style={{float: 'right', marginRight: 20}}>Tracking Number</strong>

            { rows() }

            <Form.Item>
              <Button data-testid="submit-btn"
                loading={loading}
                htmlType="submit"
                style={{marginTop: 30}}
                block
                type="primary">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </AuthTemplate>
  );
};

export default KitTracking;
