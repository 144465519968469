/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Button, Divider, FloatButton, Modal, Progress, Table, Tag, Tooltip, Checkbox
} from 'antd';
import {
  CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined, FilterFilled, IssuesCloseOutlined, StopOutlined, UndoOutlined
} from '@ant-design/icons';
import { useFeedback } from '../../contexts/feedback';
import { BackendlessOrderRecord, BackendlessSkuRecord } from '../../types';
import { orderApi, skuApi } from '../../api';
import AuthTemplate from '../../layouts/auth';
import { BackendlessOrderStatus } from '../../enums';
import OrderEdit from '../../components/OrderEdit';
import SearchHeader from '../../components/SearchHeader';

const newOrder = {
  id: '',
  bioTouchId: '',
  customerAddressLine1: '',
  customerAddressLine2: '',
  customerCity: '',
  customerName: '',
  customerState: '',
  customerZipCode: '',
  quantity: '',
  shopifyOrder: '',
  status: 'PENDING',
} as unknown as BackendlessOrderRecord;

interface StatusInfo {
  status: 'success' | 'exception' | 'normal' | 'active';
  percentage: number;
  color: 'blue' | 'green' | 'red' | 'yellow' | 'gray'
}

const OrdersPage: React.FC = () => {
  const { addMessage, showLoadingScreen } = useFeedback();

  const [data, setData] = useState<Array<BackendlessOrderRecord> | []>([]);
  const [skus, setSkus] = useState<Array<BackendlessSkuRecord> | []>([]);
  const [total, setTotal] = useState<number>(0);
  const [orderModal, setOrderModal] = useState<{ open: boolean; order: BackendlessOrderRecord }>({
    open: false,
    order: newOrder,
  });
  const [fulfillmentLoading, setFulfillmentLoading] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);

  const onFetchData = async (params: URLSearchParams) => {
    const itemsTotalCount = await orderApi.fetchOrdersCount(params);
    const items = await orderApi.fetchOrders(params);

    if (items) {
      setData([...items]);
      setTotal(itemsTotalCount);
    }
  };

  const whereQuery = (searchTerm: string) =>
    // eslint-disable-next-line max-len, implicit-arrow-linebreak
    `biotouchId like '%${searchTerm}%' or customerName like '%${searchTerm}%' or id like '%${searchTerm}%' or shopifyOrder like '%${searchTerm}%' or sku.lab_prefix like '%${searchTerm}%' or sku.sku like '%${searchTerm}%'`;

  const getStatusInfo = (status: string) : StatusInfo => {
    switch (status) {
      case BackendlessOrderStatus.PROCESSING: return {
        percentage: 70,
        status: 'active',
        color: 'blue',
      };
      case BackendlessOrderStatus.SHIPPED: return {
        percentage: 100,
        status: 'success',
        color: 'green',
      };
      case BackendlessOrderStatus.CANCELLED: return {
        percentage: 0,
        status: 'normal',
        color: 'yellow',
      };
      case BackendlessOrderStatus.ERROR: return {
        percentage: 10,
        status: 'exception',
        color: 'red',
      };
      default: return {
        percentage: 0,
        status: 'normal',
        color: 'gray',
      };
    }
  };

  const getTrackingObject = (record: BackendlessOrderRecord): string => {
    if (record.outboundTracking && record.outboundTracking.length > 0) {
      return record.outboundTracking[0].trackingNumber;
    }
    return '';
  };

  const onCancel = () => {
    setOrderModal({
      open: false,
      order: {
        id: '',
        bioTouchId: '',
        customerAddressLine1: '',
        customerAddressLine2: '',
        customerCity: '',
        customerName: '',
        customerState: '',
        customerZipCode: '',
        quantity: '',
        shopifyOrder: '',
        status: 'PENDING',
      } as unknown as BackendlessOrderRecord,
    });
  };

  const onChangeLiveOrder = (order: BackendlessOrderRecord, checked: boolean) => {
    const currentLiveOrderSku = order.liveOrderSku;
    let newLiveOrderSku: string|undefined;
    if (checked) {
      const liveOrderSku = skus.find((s) => s.sku === order.sku.sku)?.biotouchLiveOrderSku;
      newLiveOrderSku = liveOrderSku ?? 'Empty';
    } else {
      newLiveOrderSku = undefined;
    }
    if (currentLiveOrderSku !== newLiveOrderSku) {
      const index = data.findIndex(
        (record) => record.objectId === order.objectId
      );
      data[index] = {
        ...data[index],
        ...{
          ...order,
          liveOrderSku: newLiveOrderSku,
        },
      };
      setData([...data]);
    }
  };

  const onSave = async (order: BackendlessOrderRecord) => {
    try {
      await orderApi.updateOrder(order);

      if (order.sku) {
        await orderApi.addSkuRelation(order.objectId, order.sku);
      }

      const index = data.findIndex(
        (record) => record.objectId === order.objectId
      );
      data[index] = {
        ...data[index],
        ...order,
      };
      setData([...data]);
      addMessage({
        severity: 'success',
        content: 'Order updated successfully',
      });
      setOrderModal({
        open: false,
        order: newOrder,
      });
    } catch (e: any) {
      if (e.response && e.response.data) {
        addMessage({
          severity: 'error',
          content: `Error updating Order - ${e.response.data.code} ${e.response.data.message}`,
        });
      }
    } finally {
      showLoadingScreen(false);
    }
  };

  useEffect(() => {
    const fetchSkus = async () => {
      const params = new URLSearchParams();
      const where = 'biotouchSku IS NOT NULL AND biotouchLiveOrderSku IS NOT NULL';
      params.append('pageSize', '100');
      params.append('where', where);
      const allSkus = await skuApi.fetchSKUs(params);
      setSkus(allSkus);
    };

    if (data?.length === 0) {
      onClearFilters();
      fetchSkus().catch(() => {
        addMessage({
          severity: 'error',
          content: 'Error fetching SKUs data',
        });
      });
    }
  }, []);

  const onChangePagination = (page: number) => {
    const offset = (page - 1) * 10;
    const params = new URLSearchParams();
    params.set('loadRelations', 'sku.lab');
    params.append('pageSize', '0');
    params.append('offset', offset.toString());
    params.set('sortBy', 'created DESC');
    if (statusFilter) {
      const where = `status = '${statusFilter}'`;
      params.set('where', where);
    }

    onFetchData(params);
  };

  const onSetFilters = (status: string) => {
    setStatusFilter(status);
    const params = new URLSearchParams();
    params.set('loadRelations', 'sku.lab');
    params.append('pageSize', '10');
    params.append('offset', '0');
    params.set('sortBy', 'created DESC');
    const where = `status = '${status}'`;
    params.set('where', where);
    onFetchData(params);
  };

  const onClearFilters = () => {
    const params = new URLSearchParams();
    params.set('loadRelations', 'sku.lab');
    params.append('pageSize', '10');
    params.append('offset', '0');
    params.set('sortBy', 'created DESC');
    onFetchData(params);
  };

  const onAddressError = async (order: BackendlessOrderRecord) => {
    addMessage({
      severity: 'error',
      content: 'Order with non valid address',
    });

    // update db
    await orderApi.updateOrder({
      ...order,
      status: BackendlessOrderStatus.ERROR,
      errorDescription: 'Order with non valid address',
    });

    // update local
    const index = data.findIndex(
      (record) => record.objectId === order.objectId
    );
    data[index] = {
      ...data[index],
      ...{
        ...order,
        status: BackendlessOrderStatus.ERROR,
        errorDescription: 'Order with non valid address',
      },
    };
    setData([...data]);
  };

  const startFulfillment = async (order: BackendlessOrderRecord) => {
    if (confirm('Are you sure all info is correct?')) {
      if (!order.outboundCarrier) {
        addMessage({
          severity: 'error',
          content: 'Order with no outbound carrier selected',
        });
        return;
      }

      setFulfillmentLoading([order.objectId]);
      try {
        await orderApi.startFulfillment(order);

        const index = data.findIndex(
          (record) => record.objectId === order.objectId
        );
        data[index] = {
          ...data[index],
          ...{
            ...order,
            status: BackendlessOrderStatus.PROCESSING,
          },
        };
        setData([...data]);
        addMessage({
          severity: 'success',
          content: 'Order started successfully',
        });
      } catch (e: any) {
        if (e.response && e.response.data) {
          if (e.response.data.message === 'ADDRESS_ERROR') {
            await onAddressError(order);
          } else {
            addMessage({
              severity: 'error',
              content: `Error on send order to biotouch - ${e.response.data.code} ${e.response.data.message}`,
            });
          }
        }
      } finally {
        setFulfillmentLoading([]);
      }
    }
  };

  const onSearch = async (searchTerm: string) => {
    const params = new URLSearchParams();
    params.append('pageSize', '10');
    params.append('offset', '0');
    params.append('where', whereQuery(searchTerm).toString());
    onFetchData(params);
  };

  const columns = [
    {
      title: 'Bio Touch Identifier',
      dataIndex: 'bioTouchId',
      width: '10%',
    },
    {
      title: 'Sku',
      dataIndex: ['sku', 'sku'],
      width: '10%',
    },
    {
      title: 'Patient/Partner',
      dataIndex: 'customerName',
      ellipsis: true,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: '8%',
    },
    {
      title: 'Outbound Tracking',
      dataIndex: 'outboundTracking',
      ellipsis: true,
      render: (_: unknown, record: BackendlessOrderRecord) => (
        <div>
          <p>{getTrackingObject(record)}</p>
        </div>
      ),
    },
    {
      title: 'Shopify Order',
      dataIndex: 'shopifyOrder',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '15%',
      render: (_: unknown, record: BackendlessOrderRecord) => (
        <div>
          <Progress percent={getStatusInfo(record.status).percentage} status={getStatusInfo(record.status).status}/>
          <Tag color={getStatusInfo(record.status).color} key={record.objectId}>
            {`${record.status} ${record.errorDescription ? record.errorDescription : ''}`}
          </Tag>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '18%',
      render: (_: unknown, record: BackendlessOrderRecord) => (
        <span data-testid="non-selected-action">
          <div style={{ marginBottom: 5 }}>
            {
              record.status === BackendlessOrderStatus.PENDING
              && <>
                <Tooltip title="Live Order">
                  <Checkbox value={!!record.liveOrderSku} onChange={(e) => onChangeLiveOrder(record, e.target.checked)}>Live Order</Checkbox>
                </Tooltip>
                { record.liveOrderSku && <Tag title='Biotouch Live Order Sku/Code'>{record.liveOrderSku}</Tag> }
              </>
            }
          </div>
          <div>
            {
              record.status === BackendlessOrderStatus.PENDING
              && <Tooltip title="Fulfillment">
                <Button
                  onClick={() => startFulfillment(record)}
                  loading={fulfillmentLoading.includes(record.objectId)}
                  style={{ marginRight: 10 }}
                  type="primary"
                  title='Fulfillment'> Start Fulfillment </Button>
              </Tooltip>
            }
            <Tooltip title="Edit">
              <Button style={{ marginRight: 10 }} onClick={() => {
                setOrderModal({
                  open: true,
                  order: record,
                });
              }}
              type="primary"
              title='Fulfillment'>
                { record.status !== BackendlessOrderStatus.SHIPPED ? 'Edit' : 'View' }
              </Button>
            </Tooltip>
          </div>
        </span>
      ),
    },
  ];

  return (
    <AuthTemplate active='/kits'>
      <h1>Orders</h1>
      <>
        <FloatButton.Group
          trigger="hover"
          style={{ right: 24, flex: 1}}
          icon={<FilterFilled />}
        >
          <FloatButton tooltip="All" icon={<UndoOutlined />} onClick={() => onClearFilters()}/>
          <FloatButton
            tooltip="Pending"
            icon={<ExclamationCircleOutlined />} onClick={() => onSetFilters(BackendlessOrderStatus.PENDING)}/>
          <FloatButton tooltip="Processing" icon={<ClockCircleOutlined />} onClick={() => onSetFilters(BackendlessOrderStatus.PROCESSING)}/>
          <FloatButton tooltip="Shipped" icon={<CheckCircleOutlined />} onClick={() => onSetFilters(BackendlessOrderStatus.SHIPPED)}/>
          <FloatButton tooltip="Cancelled" icon={<StopOutlined />} onClick={() => onSetFilters(BackendlessOrderStatus.CANCELLED)}/>
          <FloatButton tooltip="Error" icon={<IssuesCloseOutlined />} onClick={() => onSetFilters(BackendlessOrderStatus.ERROR)}/>
        </FloatButton.Group>
      </>
      <SearchHeader searchPlaceholder='Search Order' onSearch={onSearch} onClearSearch={onClearFilters} />
      <Divider />
      <Table
        bordered
        dataSource={data}
        columns={columns}
        pagination={{
          total,
          onChange: onChangePagination,
          showSizeChanger: false,
        }}
      />
      <Modal destroyOnClose title='Edit Order' footer={null} open={orderModal.open} onCancel={onCancel}>
        <OrderEdit viewMode={orderModal.order?.status === BackendlessOrderStatus.SHIPPED} order={orderModal.order} onCancel={onCancel} onSave={onSave} />
      </Modal>
    </AuthTemplate>
  );
};
export default OrdersPage;
