import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

interface Props {
  children?: React.ReactNode;
}

const PrivateRoute = ({ children }: Props) => {
  const { isAuth } = useAuth();
  return (
    <React.Fragment>
      {isAuth() ? children : <Navigate to="/login" />}
    </React.Fragment>
  );
};

export default PrivateRoute;
