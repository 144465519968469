import React from 'react';

import { Routes, Route } from 'react-router-dom';
import SkuPage from '../pages/sku';
import KitTrackingPage from '../pages/kit-tracking';
import Login from '../pages/login';
import KitIds from '../pages/kits';
import NotFoundPage from '../pages/not-found';
import PrivateRoute from './components/private-route';
import PrintLabels from '../pages/print-labels';
import KitShopify from '../pages/kit-shopify';
import QualityControl from '../pages/quality-control';
import QualityControlDetails from '../pages/quality-control-details';
import BuildBulkOrder from '../pages/print-labels-bulk';
import OrdersPage from '../pages/order';

const MainRoute: React.FunctionComponent = ({ ...routeProps }) => (
  <Routes>
    <Route {...routeProps} path="/login" element={<Login />} />
    <Route {...routeProps} path="/kits" element={<PrivateRoute><KitIds /></PrivateRoute>}/>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <SkuPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/tracking"
      element={
        <PrivateRoute>
          <KitTrackingPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/print"
      element={
        <PrivateRoute>
          <PrintLabels />
        </PrivateRoute>
      }
    />
    <Route
      path="/shopify"
      element={
        <PrivateRoute>
          <KitShopify />
        </PrivateRoute>
      }
    />
    <Route
      path="/qc"
      element={
        <PrivateRoute>
          <QualityControl />
        </PrivateRoute>
      }
    />
    <Route
      path="/qc/:objectId"
      element={
        <PrivateRoute>
          <QualityControlDetails />
        </PrivateRoute>
      }
    />

    <Route
      path="/bulk"
      element={
        <PrivateRoute>
          <BuildBulkOrder />
        </PrivateRoute>
      }
    />

    <Route
      path="/orders"
      element={
        <PrivateRoute>
          <OrdersPage />
        </PrivateRoute>
      }
    />

    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default MainRoute;
