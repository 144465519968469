/* eslint-disable @typescript-eslint/no-explicit-any */
type Listener = (...args: any[]) => void;

class AppEventEmitter {
  private events: { [eventName: string]: Set<Listener> } = {};

  private _getEventListByName(eventName: string): Set<Listener> {
    if (typeof this.events[eventName] === 'undefined') {
      this.events[eventName] = new Set<Listener>();
    }
    return this.events[eventName];
  }

  public on(eventName: string, fn: Listener): void {
    this._getEventListByName(eventName).add(fn);
  }

  public emit(eventName: string, ...args: any[]): void {
    this._getEventListByName(eventName).forEach((fn) => {
      fn.apply(this, args);
    }, this);
  }

  public removeListener(eventName: string, fn: Listener): void {
    this._getEventListByName(eventName).delete(fn);
  }
}

export default AppEventEmitter;
