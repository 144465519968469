import ApiClient, { IApiClient } from './ApiClient';
import { BackendlessShippingQualityRecord } from '../types';

const urlSubPath = '/data/ShippingQuality';

export default class ShippingQualityApi {
  private apiClient: IApiClient;

  public constructor() {
    this.apiClient = new ApiClient(`https://${process.env.REACT_APP_BACKENDLESS_SUBDOMAIN}.backendless.app/api`);
  }

  public fetchShippingQualitysCount = async (params?: URLSearchParams): Promise<number> => {
    const data = await this.apiClient.get<number>(`${urlSubPath}/count`, { params });
    return data;
  };

  public fetchShippingQualitys = async (params?: URLSearchParams): Promise<BackendlessShippingQualityRecord[]> => {
    const data = await this.apiClient.get<BackendlessShippingQualityRecord[]>(`${urlSubPath}`, { params });
    return data;
  };

  public updateShippingQuality = async (kit: BackendlessShippingQualityRecord): Promise<BackendlessShippingQualityRecord> => {
    const data = await this.apiClient.put<BackendlessShippingQualityRecord, BackendlessShippingQualityRecord>(`${urlSubPath}/${kit.objectId}`, kit);
    return data;
  };

  public createShippingQuality = async (shippingQuality: BackendlessShippingQualityRecord): Promise<BackendlessShippingQualityRecord> => {
    const data = await this.apiClient.post<BackendlessShippingQualityRecord, BackendlessShippingQualityRecord>(`${urlSubPath}`, shippingQuality);
    return data;
  };

  public deleteShippingQuality = async (objectId: string): Promise<void> => {
    await this.apiClient.delete(`${urlSubPath}/${objectId}`);
  };
}
