import styled from 'styled-components';
import { Form } from 'antd';

const Row = styled.div`
  display:flex;
  flex-direction: row;
  background-color: #bfbfbf30;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  margin: 10px;
`;

const RowItem = styled(Form.Item)`
  display:flex;
  flex-direction: row;
  padding-right: 20px;
  flex:1;
`;

const RowDivider = styled.div`
  box-sizing: border-box;
  margin-top: 15px;
  margin-right: 20px;
  padding: 0;
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  border-block-start: 1px solid rgba(5, 5, 5, 1);
  display:flex;
  flex:0.1;
`;

export default { Row, RowItem, RowDivider };
