import React from 'react';
import {
  Button, Form, Input, Space
} from 'antd';
import { BackendlessSkuRecord } from '../../types';

interface Props {
  sku: BackendlessSkuRecord;
  onCancel: () => void;
  onSave: (sku: BackendlessSkuRecord) => void;
}

const SkuForm: React.FC<Props> = (props: Props) => {
  const { sku, onCancel, onSave } = props;

  const onFinish = (values: BackendlessSkuRecord) => {
    const updatedKit = { ...sku, ...values };
    onSave(updatedKit);
  };

  return (
    <Form data-testid='sku-form'
      name="skuForm"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={sku}
      onFinish={onFinish}
      autoComplete="off">
      <Form.Item label="Lab Prefix" name="lab_prefix" rules={[{ required: true, message: 'Please input lab prefix!' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="SKU" name="sku" rules={[{ required: true, message: 'Please input SKU!' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input description!' }]}>
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Space>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button type="primary" onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default SkuForm;
