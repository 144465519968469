import React from 'react';
import { Button, Col, Row } from 'antd';
import SearchBar from '../SearchBar';

interface SearchHeaderProps {
  onAdd?(): void;
  onSearch(searchTerm: string): void;
  onClearSearch(): void;
  searchPlaceholder: string;
}

const SearchHeader: React.FC<SearchHeaderProps> = ({
  onAdd, onSearch, onClearSearch, searchPlaceholder,
}: SearchHeaderProps) => (
  <Row>
    <Col data-testid="search-bar" span={17}>
      <SearchBar placeholder={searchPlaceholder} isLoading={false} size="large" onSearch={onSearch} onClearSearch={onClearSearch} />
    </Col>
    {
      onAdd
      && <Col offset={1} span={6}>
        <Button data-testid="add-button" shape="round" style={{ marginBottom: 10 }} size="large" type="primary" block onClick={onAdd}>
          Add New
        </Button>
      </Col>
    }
  </Row>
);

export default SearchHeader;
