import { Col, Row, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { BackendlessSkuRecord } from '../../../../types';

interface PrintLabelStepsProps {
  children: React.ReactNode,
  step: number,
  sku?: BackendlessSkuRecord
  onChange(step: number): void
}

const PrintLabelsSteps = ({
  children, step, onChange, sku,
}: PrintLabelStepsProps) => {
  const items = [
    {
      title: sku ? `${sku.sku}` : 'Select SKU',
      description: sku ? `${sku.description}` : 'Select any SKU and the number of labels to be printed.',
    },
    {
      title: 'Confirm the Lab',
      description: 'Choose the Carrier and Shipment Speed',
    },
    {
      title: 'Print Labels',
      description: 'Check the ink and select which labels you want to print! That\'s it',
    },
  ];
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  return (
    <Row>
      <Col flex={'100%'}>
        <Steps
          direction='horizontal'
          onChange={onChange}
          current={currentStep}
          items={items}/>
      </Col>
      <Col flex={'100%'}>
        {children}
      </Col>
    </Row>
  );
};
export default PrintLabelsSteps;
